import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";

import { Button, Modal, Space } from "antd";
import { URL } from "../../Redux/common/url";
import { ExportToExcel } from "../component/ExportToExcel";
import Api from "../../Redux/common/api";

function User() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  console.log(searchParams);
  console.log(page);
  console.log(searchData);

  const users_list = useSelector((state) =>
    state?.allapi?.users_list ? state?.allapi?.users_list : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = users_list?.data ? users_list?.data : [];

  useEffect(() => {
    getuserDetails();
    return () => {};
  }, [location]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [statusData2, setstatusData2] = useState({});
  const [userData, setuserData] = useState({});
  const [userData2, setuserData2] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBox2, setstatusDataBox2] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    // setuserData(e);
  };
  const hendleToViewM2 = (e) => {
    setstatusDataBox2(true);
    setuserData2(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };
  const hendleTochangedata2 = (e) => {
    const { name, value } = e.target;

    setstatusData2({ ...statusData2, [name]: value });
  };

  const hendleToSubmetUpdate = async () => {
    const response = await dispatch(
      allapiAction.driver_register(
        {
          name: statusData?.name,
          mobile: statusData?.mobile,
          address: statusData?.address,

          pageNumber: page,
        },
        page
      )
    );

    if (response?.status) {
      setstatusDataBox(false);
      setstatusData({ name: "", mobile: "", address: "" });
      if (page == 1) {
        getuserDetails();
      } else {
        navigate("/users?page=" + 1 + "&&search=" + search);
      }
    } else {
    }
  };

  const getuserDetails = () => {
    if (search) {
      dispatch(
        allapiAction.getUserListseacrch({
          pageNumber: page,
          key: search,
          role: "user",
        })
      );
    } else {
      dispatch(allapiAction.getuserList({ page, role: "user" }));
    }
  };
  const hendleToSubmetUpdate2 = () => {
    dispatch(
      allapiAction.deductFoundByAdmin({
        id: userData2?._id,
        amount: statusData2?.amount,

        pageNumber: page,
      })
    );

    setstatusDataBox2(false);
    setstatusData2({ amount: "" });
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.user_inactive({
        _id: userId,
        status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function maskNumber(number) {
    if (number) {
      const numberString = number.toString(); // Convert the number to a string
      const maskedPortion = numberString.slice(0, -2).replace(/\d/g, "*"); // Replace all but the last 2 digits with asterisks
      const lastTwoDigits = numberString.slice(-2); // Get the last 2 digits
      return maskedPortion + lastTwoDigits;
    }
    // Combine the masked portion and last 2 digits
  }

  const hendletodonaer = async (id, status) => {
    console.log(id);
    console.log(status);

    const response = await dispatch(
      allapiAction.makeDoner({
        id: id,
        is_doner: status,
        // active: 0,
      })
    );

    console.log(response);
    getuserDetails();
    // makeDoner
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Profile",
      dataIndex: "uid",
      key: "uid",
      className: "table_first_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <div style={{ width: "50px" }}>
              {item?.profile ? (
                <img
                  src={URL?.API_BASE_URL + item?.profile}
                  alt=""
                  style={{ width: "100%" }}
                />
              ) : (
                <img
                  src={"/male-placeholder-image.webp"}
                  alt=""
                  style={{ width: "100%" }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "User Name",
      dataIndex: "uid",
      key: "uid",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.uid}`)}
            >
              {item?.name}
            </span>
          </>
        );
      },
    },

    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.uid}`)}
            >
              {item?.mobile}
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Number",
    //   dataIndex: "number",
    //   key: "number",
    // },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.role}
            </span>
          </>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.address}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Winning  ",
    //   dataIndex: "winningBal",
    //   key: "winningBal",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.walletId?.winningBal}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },
    // {
    //   title: "Join Date",
    //   dataIndex: "Join Date",
    //   key: "Join Date",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {moment(item?.createdAt).format(" DD/MM/YYYY ")}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button ml-3"
    //             }
    //             onClick={() =>
    //               hendleToChangeStatus(
    //                 item?._id,
    //                 item?.status == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Student Mobile",
    //   dataIndex: "student_mobile",
    //   key: "student_mobile",
    // },
    // {
    //   title: "Parent Name",
    //   dataIndex: "parent_name",
    //   key: "parent_name",
    // },
    // {
    //   title: "Parent Mobile",
    //   dataIndex: "parent_mobile",
    //   key: "parent_mobile",
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },

    {
      title: "Date",
      dataIndex: "Join Date",
      key: "Join Date",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => hendletodonaer(item?.id, item?.is_doner ? 0 : 1)}
            >
              {moment(item?.created_at).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
    {
      title: "Is Doner",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title={item?.is_doner ? "Yes" : "No"}>
              <button
                className="filter-button2 ml-3  mt-5"
                onClick={() => hendletodonaer(item?.id, item?.is_doner ? 0 : 1)}
                // onClick={() =>
                //   navigate(`/point-request?page=1&search=${item?._id}`)
                // }
              >
                {/* Point request */}
                {item?.is_doner ? "Yes" : "No"}
              </button>
            </Tooltip>
            {/* <Tooltip title="Contact">
            
              <button
                className="filter-button2 ml-3  mt-5"
                onClick={() =>
                  navigate(`/withdrawal-request?page=1&search=${item?._id}`)
                }
              >
                Withdrawal request
              </button>
            </Tooltip> */}
            {/* <Tooltip title="Contact">
              
              <button
                className="filter-button2 ml-3  mt-5"
                onClick={() => navigate(`/battle?page=1&search=${item?._id}`)}
              >
                Battel
              </button>
            </Tooltip> */}

            {/* <Tooltip title="Add Point">
              <button
                className="filter-button2 ml-3  mt-5"
                onClick={() => hendleToViewM(item)}
              >
                Add Point
              </button>
            </Tooltip>
            <Tooltip title="Deduct  Point">
              <button
                className="filter-button2 ml-3  mt-5"
                onClick={() => hendleToViewM2(item)}
              >
                Deduct Point
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/users?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/users?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  const hendletoExportExl = async () => {
    // user_allcontact
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await Api.get(`${URL.getAllUsers}?role=user`, config);

      if (response?.data?.status) {
        const finaldata =
          response?.data?.data &&
          response?.data?.data?.map((datas, i) => {
            const datass = {
              name: datas?.name,
              mobile: datas?.mobile,
              role: datas?.role,
              address: datas?.address,
              // email: datas?.email,
            };
            return datass;
          });

        ExportToExcel(finaldata, "user");
      } else {
      }
      console.log(response?.data);
      //
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Driver"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>name</Col>
            <Col span={24}>
              <Input
                name="name"
                placeholder="enter name"
                value={statusData?.name}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>mobile</Col>
            <Col span={24}>
              <Input
                name="mobile"
                placeholder="enter mobile"
                value={statusData?.mobile}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>address</Col>
            <Col span={24}>
              <Input
                name="address"
                placeholder="enter address"
                value={statusData?.address}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      {/* deduct */}
      <Modal
        title="Deduct Amount"
        centered
        open={statusDataBox2}
        onOk={() => hendleToSubmetUpdate2(false)}
        onCancel={() => setstatusDataBox2(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="amount"
                placeholder="enter amount"
                value={statusData2?.amount}
                onChange={(e) => {
                  hendleTochangedata2(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Users"}
            subHeading={`Total ${users_list?.total || 0} Users`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Export">
            <span className="jop_btn" onClick={() => hendletoExportExl()}>
              Export
              {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
            </span>
          </Tooltip>
        </div>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          
          <Tooltip title="Add Point">
            <button className="jop_btn" onClick={() => hendleToViewM()}>
              Add Driver
            </button>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.total ? users_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default User;
