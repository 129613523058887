import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

function Box3() {
  const get_setting = useSelector((state) =>
    state.allapi.get_setting ? state.allapi.get_setting : {}
  );
  return (
    <Row gutter={[0, 20]}>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Refer Amount</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.referAmount}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
            {/* Game commision 500 or below */}
            Less then 300= {get_setting?.gamecommision}% Commision
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {get_setting?.gamecommision}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
            {/* Game commision above 500 */}

            Grater then 300 =  {get_setting?.gamecommision2}% Commision
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {get_setting?.gamecommision2}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Withdrwal commission
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.withdrwalcommission}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Minimum withdrwal
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {get_setting?.minimumwithdrwal}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Maxmum withdrawal
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.maxmumwithdrwal}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Minimum recharge
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {get_setting?.minimumrecharge}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Maxmum recharge
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.maxmumrecharge}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Vpa</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">{get_setting?.vpa}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Name</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.name}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Merchant Code</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {get_setting?.merchantCode}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              withdrawal start time
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">
              {" "}
              {moment(get_setting?.withdrawalstarttime).format("hh:mm a")}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              withdrawal end time
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {" "}
              {moment(get_setting?.withdrawalendtime).format("hh:mm a")}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              refer Upline Commision
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2"> {get_setting?.referUpCommision}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              result not declar
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {" "}
              {get_setting?.resultnotdeclar}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              wrong result submit
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2"> {get_setting?.wrongresult}</div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Box3;
