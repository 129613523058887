import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";
import ImageUploading from "react-images-uploading";
import { URL } from "../../Redux/common/url";
import { AsyncPaginate } from "react-select-async-paginate";
import DatePicker from "react-datepicker";
import Api from "../../Redux/common/api";
import { ExportToExcel } from "../component/ExportToExcel";
function Composit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const users_list = useSelector((state) =>
    state?.allapi?.get_composit_history
      ? state?.allapi?.get_composit_history
      : {}
  );

  console.log(users_list);

  const listdata = users_list?.data ? users_list?.data : [];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const hendleDelete = async (e) => {
    console.log(e);
    // deleteProduct
    const response = await dispatch(
      allapiAction.delete_pit({ id: e, page: page })
    );

    if (response?.status) {
      setShowDelete(false);
      dispatch(allapiAction.get_pits_paginate(page));
    } else {
    }

    return () => {};
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});

  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };
  const [images2, setImages2] = React.useState([]);

  const [startDate3, setStartDate3] = useState(new Date());
  const showModal = (e) => {
    console.log(e);
    setcateDetail(e);
    setStartDate3(new Date(e?.date));
    // setImages2([{ data_url: URL.API_BASE_URL + e.image }]);
    setIsModalOpen(true);
  };

  const onChange2 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages2(imageList);
  };

  const [seleceteManger2, setseleceteManger2] = useState({});

  const handleChangepit2 = (e) => {
    console.log(e);
    setseleceteManger2(e);
  };
  const handleOk = async () => {
    const data = new FormData();
    data.append("page", 1);
    data.append("composit_waste", cateDetail?.composit_waste);
    // data.append("pit_capacity", cateDetail?.pit_capacity);
    data.append("date", moment(startDate3).format("YYYY-MM-DD"));
    // data.append("category_id", 2);
    data.append("id", cateDetail?.id);

    // if (images2?.length > 0) {
    //   if (images2[0]?.file) {
    //     data.append("image", images2[0]?.file);
    //   }
    // }
    const response = await dispatch(allapiAction.update_compost(data));

    if (response?.status) {
      // dispatch(adminAction.getRoleList('dhsg'))
      setImages2([]);
      setIsModalOpen(false);
      dispatch(allapiAction.get_composit_history(page));
    } else {
    }
    // dispatch(
    //   allapiAction.update_waste_sub_category({
    //     id: cateDetail?.id,
    //     name: cateDetail?.name,
    //     page: page,
    //   })
    // );
    // dispatch(adminAction.getRoleList('dhsg'))

    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [cateDetailAdd, setcateDetailAdd] = useState({});

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };

  const showModalAdd = (e) => {
    console.log(e);
    setcateDetail(e);
    setAddModalOpen(true);
  };

  const [images, setImages] = React.useState([]);

  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  const [seleceteManger, setseleceteManger] = useState({});
  const handleChangepit = (e) => {
    console.log(e);
    setseleceteManger(e);
    // setPage(1);
    // navigate("/point-request?page=" + 1 + "&search=" + e?._id);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };
  const [startDate2, setStartDate2] = useState(new Date());
  const handleOkAdd = async () => {
    if (!cateDetailAdd?.composit_waste) {
      toast.error("please enter composit waste");
    } else if (!seleceteManger) {
      toast.error("please select pit");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("composit_waste", cateDetailAdd?.composit_waste);
      data.append("pit_id", seleceteManger?.id);
      data.append("panchayat_id", seleceteManger2?.id);
      data.append("date", moment(startDate2).format("YYYY-MM-DD"));
      //   data.append("category_id", 2);

      //   if (images?.length > 0) {
      //     data.append("image", images[0]?.file);
      //   }
      //   data.append("image", cateDetailAdd?.name);

      const response = await dispatch(allapiAction.add_composit(data));

      if (response?.status) {
        setcateDetailAdd({ name: "", pit_capacity: "" });
        // dispatch(adminAction.getRoleList('dhsg'))
        setImages([]);
        setAddModalOpen(false);
        dispatch(allapiAction.get_composit_history(1));
      } else {
      }

      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    // {
    //   title: "Image",
    //   dataIndex: "uid",
    //   key: "uid",
    //   className: "table_first_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <div style={{ width: "50px" }}>
    //           <img
    //             src={URL?.API_BASE_URL + item?.image}
    //             alt=""
    //             style={{ width: "100%" }}
    //           />
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Pit Name",
      dataIndex: "name",
      key: "name",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.pit?.name}
            </span>
          </>
        );
      },
    },
    {
      title: "compost waste",
      dataIndex: "composit_waste",
      key: "composit_waste",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.composit_waste} kg
            </span>
          </>
        );
      },
    },
    {
      title: "Panchayat",
      dataIndex: "panchayat",
      key: "panchayat",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.panchayat?.name}
            </span>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "Join Date",
      key: "Join Date",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.date}
              {/* {moment(item?.created_at).format(" DD/MM/YYYY ")} */}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   key: "name",
    // },
    // {
    //   title: "Pit capacity",
    //   dataIndex: "pit_capacity",
    //   key: "pit_capacity",
    // },
    // {
    //   title: "composit waste",
    //   dataIndex: "composit_waste",
    //   key: "composit_waste",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    // },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                                hendleModalShow(item?.id)
                              }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?.id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(allapiAction.get_composit_history(1));

    return () => {};
  }, []);

  // const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(allapiAction.allcategorySearch({ page: 1, search: e }));
      return () => {};
    } else {
      dispatch(allapiAction.get_composit_history(1));

      return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const [startDate, setStartDate] = useState(null);

  const hendletodate = (e) => {
    setStartDate(e);
    console.log(e);
    console.log(moment(e).format("YYYY-MM-DD"));
    // 2023-12-19
    setPage(1);
    if (search) {
      dispatch(
        allapiAction.get_composit_historyfildate({
          page: 1,
          search: search?.id,
          date: moment(e).format("YYYY-MM-DD"),
        })
      );

      return () => {};
    } else {
      dispatch(
        allapiAction.get_composit_historyfildate({
          page: 1,
          search: "",
          date: moment(e).format("YYYY-MM-DD"),
        })
      );
      // dispatch(allapiAction.getWasteStorageHistory(page));

      return () => {};
    }
  };

  const hendleToresat = (e) => {
    setPage(1);
    setsearch("");
    // setsearch2("");
    setStartDate(null);
    dispatch(allapiAction.get_composit_history(1));
  };
  const getToHistoryfile = (e) => {
    setsearch(e);
    setPage(1);
    if (startDate) {
      dispatch(
        allapiAction.get_composit_historyfildate({
          page: page,
          search: e?.id,
          date: moment(startDate).format("YYYY-MM-DD"),
        })
      );
    } else {
      dispatch(
        allapiAction.get_composit_historyfil({ page: 1, search: e?.id })
      );

      return () => {};
    }
  };

  const hendlePahegi = (e) => {
    setPage(e);

    if (search) {
      // dispatch(
      //   allapiAction.getWasteStorageHistorysear({ page: e, search: search?.id })
      // );
      // return () => {};

      if (startDate) {
        dispatch(
          allapiAction.get_composit_historyfildate({
            page: e,
            search: search?.id,
            date: moment(startDate).format("YYYY-MM-DD"),
          })
        );
      } else {
        dispatch(
          allapiAction.get_composit_historyfil({
            page: e,
            search: search?.id,
          })
        );

        return () => {};
      }
    } else if (startDate) {
      // dispatch(
      //   allapiAction.getWasteStorageHistorysear({ page: e, search: search?.id })
      // );
      // return () => {};

      if (search) {
        dispatch(
          allapiAction.get_composit_historyfildate({
            page: e,
            search: search?.id,
            date: moment(startDate).format("YYYY-MM-DD"),
          })
        );
      } else {
        dispatch(
          allapiAction.get_composit_historyfildate({
            page: e,
            search: "",
            date: moment(startDate).format("YYYY-MM-DD"),
          })
        );

        return () => {};
      }
    } else {
      dispatch(allapiAction.get_composit_history(e));

      return () => {};
    }
    // if (search) {
    //   dispatch(allapiAction.allcategorySearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.get_composit_history(e));

    //   return () => {};
    // }
  };

  const loadOptionsgetQuizUpdate = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    console.log(page);

    const response = await fetch(
      URL.API_BASE_URL +
        `/api/admin/get_pits_paginate_search?search=${search}&page=${page?.page}&panchayat_id=${seleceteManger2?.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const userdata = responseJSON?.data ? responseJSON?.data : [];

    const options = userdata?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    console.log(options);

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page?.page + 1,
      },
    };
  };

  const loadOptionsgetQuizUpdate2 = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    console.log(page);

    const response = await fetch(
      URL.API_BASE_URL +
        `/api/admin/getPanchayatDropDown?search=${search}&page=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const userdata = responseJSON?.data ? responseJSON?.data : [];

    const options = userdata?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    console.log(options);

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page?.page + 1,
      },
    };
  };

  const [searchm, setsearchm] = useState("");
  const [addModalfilter, setaddModalfilter] = useState(false);
  const handleCancelAddexpo = () => {
    setaddModalfilter(false);
    setsearchm("");
  };

  console.log(search);

  const handleOkAdd2 = () => {
    setaddModalfilter(false);
  };
  const hendletoExportExl = async () => {
    // user_allcontact
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const urlnew = searchm
        ? `${URL.getAllComposit}?panchayat_id=${searchm?.id}`
        : `${URL.getAllComposit}`;
      const response = await Api.get(urlnew, config);

      if (response?.data?.status) {
        const finaldata =
          response?.data?.data &&
          response?.data?.data?.map((datas, i) => {
            const datass = {
              pitname: datas?.pit?.name,
              panchayatName: datas?.panchayat?.name,
              composit_waste: datas?.composit_waste,
              date: datas?.date,
            };
            return datass;
          });
        ExportToExcel(finaldata, "Compost");
        setaddModalfilter(false);
        setsearchm("");
      } else {
        toast?.error(response?.data?.message);
      }
      console.log(response?.data);
      //
    } catch (error) {
      console.log(error);
    }
  };
  // const hendletoExportExl = async () => {
  //   // user_allcontact
  //   try {
  //     const token = await localStorage.getItem("admin_token");

  //     const config = {
  //       headers: {
  //         Authorization: "Bearer " + token,
  //       },
  //     };
  //     // dispatch(loading(true));

  //     const urlnew = search ? `${URL.getAllComposit}?panchayat_id=${search?.id}` : `${URL.getAllComposit}`
  //     const response = await Api.get(urlnew, config);

  //     if (response?.data?.status) {
  //       const finaldata =
  //         response?.data?.data &&
  //         response?.data?.data?.map((datas, i) => {
  //           const datass = {
  //             pitname: datas?.pit?.name,
  //             panchayatName: datas?.panchayat?.name,
  //             composit_waste: datas?.composit_waste,
  //             date: datas?.date,
  //           };
  //           return datass;
  //         });
  //       ExportToExcel(finaldata, "Compost");
  //     } else {
  //     }
  //     console.log(response?.data);
  //     //
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div>
      {/* update */}
      <Modal
        title="Update Composit"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="composit waste"
            name="composit_waste"
            type="number"
            value={cateDetail?.composit_waste}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Date</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <DatePicker
            className="form-control"
            selected={startDate3}
            onChange={(date) => setStartDate3(date)}
            placeholderText="Select a date"
            // showTimeSelect
            // showTimeSelectOnly
            // timeIntervals={15}
            // timeCaption="Time"
          />
        </Col>
        {/* <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="pit capacity"
            name="pit_capacity"
            type="number"
            value={cateDetail?.pit_capacity}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col> */}
        {/* <Col span={24}>Image</Col>
        <Col span={24}>
          <ImageUploading
            // multiple
            value={images2}
            onChange={onChange2}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  className="btn btn-primary btn-sm rounded"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Click or Drop here
                </button>
                &nbsp;
                
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image["data_url"]} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded mb-2"
                        onClick={() => onImageUpdate(index)}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-primary btn-sm rounded"
                        onClick={() => onImageRemove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </Col> */}
      </Modal>

      {/* create */}
      <Modal
        title="Add Composit"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          <label>Compost waste</label>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="Compost waste"
            name="composit_waste"
            type="number"
            value={cateDetailAdd?.composit_waste}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Please select Panchayat</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <AsyncPaginate
            // key={managerKey}
            placeholder="select paynchayat"
            value={seleceteManger2}
            loadOptions={loadOptionsgetQuizUpdate2}
            onChange={handleChangepit2}
            additional={{
              page: 1,
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          <label>Select pit</label>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          {/* <input
            autoComplete="new-off"
            className="form-control"
            placeholder="pit capacity"
            name="pit_capacity"
            type="number"
            value={cateDetailAdd?.pit_capacity}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          /> */}

          <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetQuizUpdate}
            onChange={handleChangepit}
            placeholder="select pit"
            additional={{
              page: 1,
            }}
          />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Date</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <DatePicker
            className="form-control"
            selected={startDate2}
            onChange={(date) => setStartDate2(date)}
            placeholderText="Select a date"
            // showTimeSelect
            // showTimeSelectOnly
            // timeIntervals={15}
            // timeCaption="Time"
          />
        </Col>

        {/* <Col span={24}>Image</Col>
        <Col span={24}>
          <ImageUploading
            // multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  className="btn btn-primary btn-sm rounded"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Click or Drop here
                </button>
                &nbsp;
            
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image["data_url"]} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded mb-2"
                        onClick={() => onImageUpdate(index)}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-primary btn-sm rounded"
                        onClick={() => onImageRemove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </Col> */}
      </Modal>

      {/* filter */}
      <Modal
        title="Export"
        open={addModalfilter}
        onOk={hendletoExportExl}
        onCancel={handleCancelAddexpo}
      >
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Select Panchayat</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <AsyncPaginate
            // key={managerKey}
            placeholder="select paynchayat"
            value={searchm}
            loadOptions={loadOptionsgetQuizUpdate2}
            onChange={setsearchm}
            additional={{
              page: 1,
            }}
          />
        </Col>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Compost Outcome"}
            subHeading={`Total ${users_list?.total || 0} Compost Out come`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          <AsyncPaginate
            // key={managerKey}
            placeholder="select paynchayat"
            value={search}
            loadOptions={loadOptionsgetQuizUpdate2}
            onChange={getToHistoryfile}
            additional={{
              page: 1,
            }}
          />
        </Col>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          <DatePicker
            className="form-control"
            selected={startDate}
            onChange={(date) => hendletodate(date)}
            placeholderText="Select a date"
            // showTimeSelect
            // showTimeSelectOnly
            // timeIntervals={15}
            // timeCaption="Time"
          />
        </Col>
        <Col md={2} sm={5} xs={12} className="pl-5  ml-5">
          <div className="flex1 flex justify-content-end align-items-center">
            <Tooltip title="Reset">
              <span className="jop_btn" onClick={() => hendleToresat()}>
                Reset
                {/* <SvgIcon.Add
                  width={40}
                  height={40}
                  className="cursor-pointer"
                /> */}
              </span>
            </Tooltip>
          </div>
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Export">
            <span className="jop_btn" onClick={() => setaddModalfilter(true)}>
              Export
              {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
            </span>
          </Tooltip>
          <Tooltip title="Add">
            <span className="jop_btn ml-5" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.total ? users_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Composit;
