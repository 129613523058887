import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import ImageUploading from "react-images-uploading";
import { Button, Modal, Space } from "antd";
import { URL } from "../../Redux/common/url";
import Api from "../../Redux/common/api";
import { ExportToExcel } from "../component/ExportToExcel";

function Driver() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  console.log(searchParams);
  console.log(page);
  console.log(searchData);

  const users_list = useSelector((state) =>
    state?.allapi?.users_list ? state?.allapi?.users_list : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = users_list?.data ? users_list?.data : [];

  useEffect(() => {
    getuserDetails();
    return () => {};
  }, [location]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [statusData2, setstatusData2] = useState({});
  const [userData, setuserData] = useState({});
  const [userData2, setuserData2] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBox2, setstatusDataBox2] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    // setuserData(e);
  };
  const hendleToViewM2 = (e) => {
    setstatusDataBox2(true);
    setuserData2(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };
  const hendleTochangedata2 = (e) => {
    const { name, value } = e.target;

    setstatusData2({ ...statusData2, [name]: value });
  };

  const [images, setImages] = React.useState([]);
  const [images2, setImages2] = React.useState([]);

  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };
  const onChange2 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages2(imageList);
  };

  const hendleToSubmetUpdate = async () => {
    const data = new FormData();
    data.append("page", 1);
    data.append("name", statusData?.name);
    data.append("mobile", statusData?.mobile);
    data.append("address", statusData?.address);
    data.append("vehicle_number", statusData?.vehicle_number);

    if (images?.length > 0) {
      data.append("vehicle_image", images[0]?.file);
    }
    if (images2?.length > 0) {
      data.append("driving_licence", images2[0]?.file);
    }
    const response = await dispatch(allapiAction.driver_register(data, page));

    if (response?.status) {
      setstatusDataBox(false);
      setstatusData({ name: "", mobile: "", address: "" });
      if (page == 1) {
        getuserDetails();
      } else {
        navigate("/driver?page=" + 1 + "&search=" + search);
      }
    } else {
    }
  };

  const getuserDetails = () => {
    if (search) {
      dispatch(
        allapiAction.getUserListseacrch({
          pageNumber: page,
          key: search,
          role: "driver",
        })
      );
    } else {
      dispatch(allapiAction.getuserList({ page, role: "driver" }));
    }
  };
  const hendleToSubmetUpdate2 = () => {
    dispatch(
      allapiAction.deductFoundByAdmin({
        id: userData2?._id,
        amount: statusData2?.amount,

        pageNumber: page,
      })
    );

    setstatusDataBox2(false);
    setstatusData2({ amount: "" });
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = async (e, status) => {
    const response = await dispatch(
      allapiAction.user_inactive({
        id: e,
        active: status,
        // active: 0,
        // pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);

    if (response?.status) {
      getuserDetails();
    }

    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function maskNumber(number) {
    if (number) {
      const numberString = number.toString(); // Convert the number to a string
      const maskedPortion = numberString.slice(0, -2).replace(/\d/g, "*"); // Replace all but the last 2 digits with asterisks
      const lastTwoDigits = numberString.slice(-2); // Get the last 2 digits
      return maskedPortion + lastTwoDigits;
    }
    // Combine the masked portion and last 2 digits
  }

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Profile",
      dataIndex: "uid",
      key: "uid",
      className: "table_first_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <div style={{ width: "50px" }}>
              {item?.profile ? (
                <img
                  src={URL?.API_BASE_URL + item?.profile}
                  alt=""
                  style={{ width: "100%" }}
                />
              ) : (
                <img
                  src={"/male-placeholder-image.webp"}
                  alt=""
                  style={{ width: "100%" }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Driving Licence",
      dataIndex: "driving_licence",
      key: "driving_licence",
      className: "table_first_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <div style={{ width: "50px" }}>
              {item?.vehicle?.driving_licence ? (
                <img
                  src={URL?.API_BASE_URL + item?.vehicle?.driving_licence}
                  alt=""
                  style={{ width: "100%" }}
                />
              ) : (
                <img
                  src={"/No_image_available.svg.png"}
                  alt=""
                  style={{ width: "100%" }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Vehicle image",
      dataIndex: "uid",
      key: "uid",
      className: "table_first_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <div style={{ width: "50px" }}>
              {item?.vehicle?.vehicle_image ? (
                <img
                  src={URL?.API_BASE_URL + item?.vehicle?.vehicle_image}
                  alt=""
                  style={{ width: "100%" }}
                />
              ) : (
                <img
                  src={"/No_image_available.svg.png"}
                  alt=""
                  style={{ width: "100%" }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Vehicle Number",
      dataIndex: "vehicle_number",
      key: "vehicle_number",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            {/* <Tooltip title="Offline">
                <button
                  className="filter-button5red mr-3 mb-3"
                  // onClick={() => navigate(`/user-details/${item?.id}`)}
                ></button>
              </Tooltip> */}
            <span
              className="cursor-pointer coustomNevc mt-20"
              // onClick={() => navigate(`/user-details/${item?.id}`)}
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.vehicle?.vehicle_number}
            </span>
          </>
        );
      },
    },
    {
      title: "User Name",
      dataIndex: "uid",
      key: "uid",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            {/* <Tooltip title="Offline">
                <button
                  className="filter-button5red mr-3 mb-3"
                  // onClick={() => navigate(`/user-details/${item?.id}`)}
                ></button>
              </Tooltip> */}
            <span
              className="cursor-pointer coustomNevc mt-20"
              // onClick={() => navigate(`/user-details/${item?.id}`)}
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.name}
            </span>
          </>
        );
      },
    },

    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer "
              // onClick={() => navigate(`/user-details/${item?.uid}`)}
            >
              {item?.mobile}
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Number",
    //   dataIndex: "number",
    //   key: "number",
    // },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.role}
            </span>
          </>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.address}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Winning  ",
    //   dataIndex: "winningBal",
    //   key: "winningBal",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.walletId?.winningBal}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },
    // {
    //   title: "Join Date",
    //   dataIndex: "Join Date",
    //   key: "Join Date",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {moment(item?.createdAt).format(" DD/MM/YYYY ")}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button ml-3"
    //             }
    //             onClick={() =>
    //               hendleToChangeStatus(
    //                 item?._id,
    //                 item?.status == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Student Mobile",
    //   dataIndex: "student_mobile",
    //   key: "student_mobile",
    // },
    // {
    //   title: "Parent Name",
    //   dataIndex: "parent_name",
    //   key: "parent_name",
    // },
    // {
    //   title: "Parent Mobile",
    //   dataIndex: "parent_mobile",
    //   key: "parent_mobile",
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },

    {
      title: "Date",
      dataIndex: "Join Date",
      key: "Join Date",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.created_at).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Track">
              <button
                className="filter-button2 ml-3  mt-5"
                onClick={() => navigate(`/user-details/${item?.id}`)}
              >
                Track
              </button>
            </Tooltip>

            <Tooltip title={item?.active ? "Active" : "Inactive"}>
              <button
                className="filter-button2 ml-3  mt-5"
                onClick={() => {
                  // navigate(`/withdrawal-request?page=1&search=${item?._id}`)
                  handleOk(item?.id, item?.active ? 0 : 1);
                }}
              >
                {item?.active ? "Active" : "Inactive"}
              </button>
            </Tooltip>
            {/* <Tooltip title="Contact">
              
              <button
                className="filter-button2 ml-3  mt-5"
                onClick={() => navigate(`/battle?page=1&search=${item?._id}`)}
              >
                Battel
              </button>
            </Tooltip> */}

            {/* <Tooltip title="Add Point">
              <button
                className="filter-button2 ml-3  mt-5"
                onClick={() => hendleToViewM(item)}
              >
                Add Point
              </button>
            </Tooltip>
            <Tooltip title="Deduct  Point">
              <button
                className="filter-button2 ml-3  mt-5"
                onClick={() => hendleToViewM2(item)}
              >
                Deduct Point
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/driver?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/driver?page=" + e + "&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  const hendletoExportExl = async () => {
    // user_allcontact
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await Api.get(`${URL.getAllUsers}?role=driver`, config);

      if (response?.data?.status) {
        const finaldata =
          response?.data?.data &&
          response?.data?.data?.map((datas, i) => {
            const datass = {
              name: datas?.name,
              mobile: datas?.mobile,
              role: datas?.role,
              address: datas?.address,
              // email: datas?.email,
            };
            return datass;
          });
        ExportToExcel(finaldata, "driver");
      } else {
      }
      console.log(response?.data);
      //
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Driver"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>name</Col>
            <Col span={24}>
              <Input
                name="name"
                placeholder="enter name"
                value={statusData?.name}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>mobile</Col>
            <Col span={24}>
              <Input
                name="mobile"
                placeholder="enter mobile"
                value={statusData?.mobile}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>address</Col>
            <Col span={24}>
              <Input
                name="address"
                placeholder="enter address"
                value={statusData?.address}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>vehicle number</Col>
            <Col span={24}>
              <Input
                name="vehicle_number"
                placeholder="enter vehicle number"
                value={statusData?.vehicle_number}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            <Col span={24}>vehicle Image</Col>
            <Col span={24}>
              <ImageUploading
                // multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    <button
                      className="btn btn-primary btn-sm rounded"
                      style={isDragging ? { color: "red" } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click
                    </button>
                    &nbsp;
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image["data_url"]} alt="" width="100" />
                        <div className="image-item__btn-wrapper">
                          <button
                            className="btn btn-primary btn-sm rounded mb-2"
                            onClick={() => onImageUpdate(index)}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-primary btn-sm rounded"
                            onClick={() => onImageRemove(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
            </Col>
            <Col span={24}>Driving licence </Col>
            <Col span={24}>
              <ImageUploading
                // multiple
                value={images2}
                onChange={onChange2}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    <button
                      className="btn btn-primary btn-sm rounded"
                      style={isDragging ? { color: "red" } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click
                    </button>
                    &nbsp;
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item">
                        <img src={image["data_url"]} alt="" width="100" />
                        <div className="image-item__btn-wrapper">
                          <button
                            className="btn btn-primary btn-sm rounded mb-2"
                            onClick={() => onImageUpdate(index)}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-primary btn-sm rounded"
                            onClick={() => onImageRemove(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* deduct */}
      <Modal
        title="Deduct Amount"
        centered
        open={statusDataBox2}
        onOk={() => hendleToSubmetUpdate2(false)}
        onCancel={() => setstatusDataBox2(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="amount"
                placeholder="enter amount"
                value={statusData2?.amount}
                onChange={(e) => {
                  hendleTochangedata2(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Driver"}
            subHeading={`Total ${users_list?.total || 0} Driver`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          {/* <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip> */}
          <Tooltip title="Export">
            <span className="jop_btn" onClick={() => hendletoExportExl()}>
              Export
              {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
            </span>
          </Tooltip>
          <Tooltip title="Add Point ">
            <button className="jop_btn ml-5" onClick={() => hendleToViewM()}>
              Add Driver
            </button>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.total ? users_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Driver;
