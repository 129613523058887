import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";
import ImageUploading from "react-images-uploading";
import { URL } from "../../Redux/common/url";
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment";
function DryStoreWaste() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const users_list = useSelector((state) =>
    state?.allapi?.get_sale_list ? state?.allapi?.get_sale_list : {}
  );

  console.log(users_list);

  const listdata = users_list?.data ? users_list?.data : [];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const hendleDelete = async (e) => {
    console.log(e);
    // deleteProduct
    const response = await dispatch(
      allapiAction.delete_pit({ id: e, page: page })
    );

    if (response?.status) {
      setShowDelete(false);
      dispatch(allapiAction.get_pits_paginate(page));
    } else {
    }

    return () => {};
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});

  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };
  const [images2, setImages2] = React.useState([]);
  const showModal = (e) => {
    console.log(e);
    setcateDetail(e);

    // setImages2([{ data_url: URL.API_BASE_URL + e.image }]);
    setIsModalOpen(true);
  };

  const onChange2 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages2(imageList);
  };
  const handleOk = async () => {
    const data = new FormData();
    data.append("page", 1);
    data.append("name", cateDetail?.name);
    data.append("pit_capacity", cateDetail?.pit_capacity);
    // data.append("category_id", 2);
    data.append("id", cateDetail?.id);

    // if (images2?.length > 0) {
    //   if (images2[0]?.file) {
    //     data.append("image", images2[0]?.file);
    //   }
    // }
    const response = await dispatch(allapiAction.update_pit(data));

    if (response?.status) {
      // dispatch(adminAction.getRoleList('dhsg'))
      setImages2([]);
      setIsModalOpen(false);
      dispatch(allapiAction.get_donation(page));
    } else {
    }
    // dispatch(
    //   allapiAction.update_waste_sub_category({
    //     id: cateDetail?.id,
    //     name: cateDetail?.name,
    //     page: page,
    //   })
    // );
    // dispatch(adminAction.getRoleList('dhsg'))

    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [cateDetailAdd, setcateDetailAdd] = useState({});

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };

  const showModalAdd = (e) => {
    console.log(e);
    setcateDetail(e);
    setAddModalOpen(true);
  };

  const [images, setImages] = React.useState([]);

  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  const [seleceteManger2, setseleceteManger2] = useState({});
  const [seleceteManger, setseleceteManger] = useState({});
  const handleChangepit = (e) => {
    console.log(e);
    setseleceteManger(e);
  };
  const handleChangepit2 = (e) => {
    console.log(e);
    setseleceteManger2(e);
  };
  const handleOkAdd = async () => {
    /* if (!cateDetailAdd?.waste_price) {
      toast.error("please enter waste price");
    } else */ if (!seleceteManger2) {
      toast.error("please please select  panchayt");
    } else if (!cateDetailAdd?.waste_weight) {
      toast.error("please entrt  waste weight");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("category_id", "2");
      //   data.append("waste_price", cateDetailAdd?.waste_price);
      data.append("waste_weight", cateDetailAdd?.waste_weight);
      data.append("panchayat_id", seleceteManger2?.id);
    //   if (cateDetailAdd?.category_id == "2") {
        data.append("sub_category_id", seleceteManger?.id);
    //   }

      const response = await dispatch(allapiAction.store_waste(data));

      if (response?.status) {
        setcateDetailAdd({
          waste_price: "",
          waste_weight: "",
          category_id: "",
        });
        // dispatch(adminAction.getRoleList('dhsg'))
        setImages([]);
        setAddModalOpen(false);
        dispatch(allapiAction.getWasteSale(1));
      } else {
      }

      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    // {
    //   title: "Image",
    //   dataIndex: "uid",
    //   key: "uid",
    //   className: "table_first_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <div style={{ width: "50px" }}>
    //           <img
    //             src={URL?.API_BASE_URL + item?.image}
    //             alt=""
    //             style={{ width: "100%" }}
    //           />
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.category?.name}
            </span>
          </>
        );
      },
    },
    {
      title: "Sub category",
      dataIndex: "subcategory",
      key: "subcategory",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.subcategory?.name}
            </span>
          </>
        );
      },
    },
    {
      title: "waste_weight",
      dataIndex: "waste_weight",
      key: "waste_weight",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.waste_weight} Kg
            </span>
          </>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "waste_price",
      key: "waste_price",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.waste_price} ₹
            </span>
          </>
        );
      },
    },
    {
        title: "Date",
        dataIndex: "Join Date",
        key: "Join Date",
        className: "table_action_col",
        render: (value, item, index) => {
          return (
            <>
              {/* <Tooltip title="Contact"> */}
              <span
                className="cursor-pointer"
                // onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                {moment(item?.created_at).format(" DD/MM/YYYY ")}
              </span>
            </>
          );
        },
      },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   key: "name",
    // },
    // {
    //   title: "Pit capacity",
    //   dataIndex: "pit_capacity",
    //   key: "pit_capacity",
    // },
    // {
    //   title: "composit waste",
    //   dataIndex: "composit_waste",
    //   key: "composit_waste",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    // },

    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Edit">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => {
    //               showModal(item);
    //             }}
    //           >
    //             <SvgIcon.Edit className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip> */}
    //         {/* <Tooltip title="Delete">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => {
    //                             hendleModalShow(item?.id)
    //                           }}
    //           >
    //             <SvgIcon.deletebtn className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip> */}
    //         <Tooltip title="Delete">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => {
    //               showDeleteConfirm(item?.id);
    //             }}
    //           >
    //             <SvgIcon.deletebtn className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    dispatch(allapiAction.getWasteSale(1));

    return () => {};
  }, []);

  // const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(allapiAction.allcategorySearch({ page: 1, search: e }));
      return () => {};
    } else {
      dispatch(allapiAction.getWasteSale(1));

      return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      dispatch(allapiAction.allcategorySearch({ page: e, search: search }));
      return () => {};
    } else {
      dispatch(allapiAction.getWasteSale(e));

      return () => {};
    }
  };

  const loadOptionsgetQuizUpdate2 = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    console.log(page);

    const response = await fetch(
      URL.API_BASE_URL +
        `/api/admin/getPanchayatDropDown?search=${search}&page=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const userdata = responseJSON?.data ? responseJSON?.data : [];

    const options = userdata?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    console.log(options);

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page?.page + 1,
      },
    };
  };

  const loadOptionsgetQuizUpdate = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    console.log(page);

    const response = await fetch(
      URL.API_BASE_URL +
        `/api/admin/getWasteSubCategoryPaginate?search=${search}&page=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const userdata = responseJSON?.data ? responseJSON?.data : [];

    const options = userdata?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    console.log(options);

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page?.page + 1,
      },
    };
  };

  return (
    <div>
      {/* update */}
      <Modal
        title="Update Dry Store Waste"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="composit waste"
            name="composit_waste"
            type="number"
            value={cateDetail?.composit_waste}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        {/* <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="pit capacity"
            name="pit_capacity"
            type="number"
            value={cateDetail?.pit_capacity}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col> */}
        {/* <Col span={24}>Image</Col>
        <Col span={24}>
          <ImageUploading
            // multiple
            value={images2}
            onChange={onChange2}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  className="btn btn-primary btn-sm rounded"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Click or Drop here
                </button>
                &nbsp;
                
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image["data_url"]} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded mb-2"
                        onClick={() => onImageUpdate(index)}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-primary btn-sm rounded"
                        onClick={() => onImageRemove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </Col> */}
      </Modal>

      {/* create */}
      <Modal
        title="Add Dry Store Waste"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Please select Panchayat</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <AsyncPaginate
            // key={managerKey}
            value={seleceteManger2}
            loadOptions={loadOptionsgetQuizUpdate2}
            onChange={handleChangepit2}
            additional={{
              page: 1,
            }}
          />
        </Col>
        {/* <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Please select category</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
         

          <select
            name="category_id"
            className="form-control"
            value={cateDetailAdd?.category_id}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          >
            <option value={""}> Select Category</option>
            <option value={"1"}> Wet</option>
            <option value={"2"}> Dry</option>
          </select>
        </Col> */}
        {/* {cateDetailAdd?.category_id == "2" && ( */}
          <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
            <lable>Please select sub category</lable>
          </Col>
        {/* )} */}
        {/* {cateDetailAdd?.category_id == "2" && ( */}
          <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
            {/* <input
            autoComplete="new-off"
            className="form-control"
            placeholder="pit capacity"
            name="pit_capacity"
            type="number"
            value={cateDetailAdd?.pit_capacity}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          /> */}

            <AsyncPaginate
              // key={managerKey}
              value={seleceteManger}
              loadOptions={loadOptionsgetQuizUpdate}
              onChange={handleChangepit}
              additional={{
                page: 1,
              }}
            />
          </Col>
        {/* )} */}
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Please enter waste weight</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="waste weight kg"
            name="waste_weight"
            type="number"
            value={cateDetailAdd?.waste_weight}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>

        {/* <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="waste price "
            name="waste_price"
            type="number"
            value={cateDetailAdd?.waste_price}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col> */}

        {/* <Col span={24}>Image</Col>
        <Col span={24}>
          <ImageUploading
            // multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  className="btn btn-primary btn-sm rounded"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Click or Drop here
                </button>
                &nbsp;
            
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image["data_url"]} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded mb-2"
                        onClick={() => onImageUpdate(index)}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-primary btn-sm rounded"
                        onClick={() => onImageRemove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </Col> */}
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Dry Store Waste"}
            subHeading={`Total ${users_list?.total || 0} Dry Store Waste`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.total ? users_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default DryStoreWaste;
