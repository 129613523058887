import { UsergroupDeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IMAGES from "../const/Image";

const Boxes2 = () => {
  const navigate = useNavigate();
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );
  const get_notifaction_all = useSelector((state) =>
    state?.allapi?.get_notifaction_all ? state?.allapi?.get_notifaction_all : {}
  );

  console.log(get_notifaction_all);
  // const totalUsers = useSelector(state => state.dashboard.totalUsers || "");
  // const totalFaqs = useSelector(state => state.dashboard.totalFaqs || "");
  // const TotalJobsAdd = useSelector(state => state.dashboard.TotalJobs || "");
  // const totalJobApp = useSelector(state => state.jobAppliction.totalJobApp || 0);
  // console.log(totalJobApp);
  return (
    <Row gutter={[0, 20]}>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Total User</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">
              {deshboard?.user?.toLocaleString("en-IN")}
            </div>
            {/* <div style={{ width: "25px" }}>
              <img src={IMAGES.Users} style={{ width: "100%" }} alt="" />
            </div> */}
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/driver")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Total Driver</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.driver?.toLocaleString("en-IN")}
            </div>
            {/* <div style={{ width: "25px" }}>
              <img src={IMAGES.Calendar} style={{ width: "100%" }} alt="" />
            </div> */}
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/composit")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total compost
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">
              {deshboard?.composit_wet?.total_composit_wet?.toLocaleString(
                "en-IN"
              )}{" "}
              Kg
            </div>
            {/* <div style={{ width: "25px" }}>
              <img src={IMAGES.Trophy} style={{ width: "100%" }} alt="" />
            </div> */}
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          onClick={() => navigate("/sale-waste")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Compost sale </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.composit_wet?.composit_sale_wet?.toLocaleString(
                "en-IN"
              )}{" "}
              Kg
            </div>
            {/* <div style={{ width: "25px" }}>
              <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
            </div> */}
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Compost profit
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">
              {deshboard?.composit_wet?.composit_profit_wet?.toLocaleString(
                "en-IN"
              )}{" "}
              ₹
            </div>{" "}
            {/* <div style={{ width: "25px" }}>
              <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
            </div> */}
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Dry waste weight
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.dry_waste?.waste_weight?.toLocaleString("en-IN")} Kg
            </div>{" "}
            {/* <div style={{ width: "25px" }}>
              <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
            </div> */}
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Dry Waste sale
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">
              {deshboard?.dry_waste?.waste_sale?.toLocaleString("en-IN")} Kg
            </div>{" "}
            {/* <div style={{ width: "25px" }}>
              <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
            </div> */}
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Dry waste profit
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.dry_waste?.waste_profit?.toLocaleString("en-IN")} ₹
            </div>{" "}
            {/* <div style={{ width: "25px" }}>
              <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
            </div> */}
          </div>
        </div>
      </Col>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Today Deposit</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">
              {deshboard?.todaydepositeBalance?.toLocaleString("en-IN")}
            </div>{" "}
            <div style={{ width: "25px" }}>
              <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Today Withdrawal
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.todaywithdrawalAmount?.toLocaleString("en-IN")}
            </div>{" "}
            <div style={{ width: "25px" }}>
              <img src={IMAGES.Gamepad} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total User Wallet Balance
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">
              {deshboard?.totalwalletBalance?.toLocaleString("en-IN")}
            </div>{" "}
            <div style={{ width: "25px" }}>
              <img src={IMAGES.Money} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Total Block User
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.blockuserCount?.toLocaleString("en-IN")}
            </div>{" "}
            <div style={{ width: "25px" }}>
              <img src={IMAGES.Users} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Today Won Amount
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">
              {deshboard?.todayWonBalance?.toLocaleString("en-IN")}
            </div>{" "}
            <div style={{ width: "25px" }}>
              <img src={IMAGES.Money} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Today Admin Commission
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.todayadminWinCommission?.toLocaleString("en-IN")}
            </div>{" "}
            <div style={{ width: "25px" }}>
              <img src={IMAGES.Money1} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Admin Commission
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">
              {deshboard?.adminWinCommission?.toLocaleString("en-IN")}
            </div>{" "}
            <div style={{ width: "25px" }}>
              <img src={IMAGES.Money1} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Today Refer Commission
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText color-risk">
              {deshboard?.todayrfcommissiom?.toLocaleString("en-IN")}
            </div>{" "}
            <div style={{ width: "25px" }}>
              <img src={IMAGES.Money1} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Refer Commission
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">
              {deshboard?.totalrfcommissiom?.toLocaleString("en-IN")}
            </div>{" "}
            <div style={{ width: "25px" }}>
              <img src={IMAGES.Money1} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>
      </Col> */}

      {/* <Col span={6}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/question")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Question
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText">{deshboard?.total_question || 0}</div>
          </div>
        </div>
      </Col> */}
    </Row>
  );
};

export default Boxes2;
