import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";
import ImageUploading from "react-images-uploading";
import { URL } from "../../Redux/common/url";
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment";
import DatePicker from "react-datepicker";
function PitHistory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useParams();
  const data2 = useLocation();
  const queryParams = new URLSearchParams(data2.search);

  // get the value of the 'name' query parameter
  const nameParam = queryParams.get("name");
  console.log(nameParam);
  const [page, setPage] = useState(1);

  const users_list = useSelector((state) =>
    state?.allapi?.get_pit_history_list
      ? state?.allapi?.get_pit_history_list
      : {}
  );

  console.log(users_list);

  const listdata = users_list?.data ? users_list?.data : [];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const hendleDelete = async (e) => {
    console.log(e);
    // deleteProduct
    const response = await dispatch(
      allapiAction.deletePitWasteHistory({ id: e, page: page })
    );

    if (response?.status) {
      setShowDelete(false);
      dispatch(allapiAction.getPitWiseHistory({ page: page, pit: data?.id }));
    } else {
    }

    return () => {};
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});

  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };
  const [images2, setImages2] = React.useState([]);
  const [startDate3, setStartDate3] = useState(new Date());
  const showModal = (e) => {
    console.log(e);
    setcateDetail(e);
    setStartDate3(new Date(e?.date));
    // setImages2([{ data_url: URL.API_BASE_URL + e.image }]);
    setIsModalOpen(true);
  };

  const onChange2 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages2(imageList);
  };
  const handleOk = async () => {
    const data2 = new FormData();
    data2.append("page", 1);
    // data.append("name", cateDetail?.name);
    // data.append("pit_capacity", cateDetail?.pit_capacity);

    data2.append("waste_weight", cateDetail?.waste_weight);
    data2.append("soil", cateDetail?.soil);
    data2.append("dung", cateDetail?.dung);
    data2.append("water", cateDetail?.water);
    // data.append("category_id", 2);
    data2.append("id", cateDetail?.id);
    data2.append("date", moment(startDate3).format("YYYY-MM-DD"));

    // if (images2?.length > 0) {
    //   if (images2[0]?.file) {
    //     data.append("image", images2[0]?.file);
    //   }
    // }
    const response = await dispatch(allapiAction.updateWasteInPit(data2));

    if (response?.status) {
      // dispatch(adminAction.getRoleList('dhsg'))
      setImages2([]);
      setIsModalOpen(false);
      dispatch(allapiAction.getPitWiseHistory({ page: page, pit: data?.id }));
    } else {
    }
    // dispatch(
    //   allapiAction.update_waste_sub_category({
    //     id: cateDetail?.id,
    //     name: cateDetail?.name,
    //     page: page,
    //   })
    // );
    // dispatch(adminAction.getRoleList('dhsg'))

    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [cateDetailAdd, setcateDetailAdd] = useState({});

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };

  const showModalAdd = (e) => {
    console.log(e);
    setcateDetail(e);
    setAddModalOpen(true);
  };

  const [images, setImages] = React.useState([]);

  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  const [seleceteManger, setseleceteManger] = useState({});
  const handleChangepit = (e) => {
    console.log(e);
    setseleceteManger(e);
    // setPage(1);
    // navigate("/point-request?page=" + 1 + "&search=" + e?._id);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const [startDate2, setStartDate2] = useState(new Date());
  const handleOkAdd = async () => {
    if (!cateDetailAdd?.waste_weight) {
      toast.error("please enter  waste");
    } else if (!cateDetailAdd?.soil) {
      toast.error("please select soil");
    } else if (!cateDetailAdd?.dung) {
      toast.error("please select dung");
    } else if (!cateDetailAdd?.water) {
      toast.error("please select water");
    } /* else if (!seleceteManger) {
      toast.error("please select pit");
    } */ else {
      const data2 = new FormData();
      data2.append("page", 1);
      data2.append("pit_id", data?.id);
      data2.append("waste_weight", cateDetailAdd?.waste_weight);
      data2.append("soil", cateDetailAdd?.soil);
      data2.append("dung", cateDetailAdd?.dung);
      data2.append("water", cateDetailAdd?.water);
      data2.append("date", moment(startDate2).format("YYYY-MM-DD"));
      //   data.append("category_id", 2);

      //   if (images?.length > 0) {
      //     data.append("image", images[0]?.file);
      //   }
      //   data.append("image", cateDetailAdd?.name);

      const response = await dispatch(allapiAction.addWasteInPit(data2));

      if (response?.status) {
        setcateDetailAdd({ name: "", pit_capacity: "" });
        // dispatch(adminAction.getRoleList('dhsg'))
        setImages([]);
        setAddModalOpen(false);
        dispatch(allapiAction.getPitWiseHistory({ page: 1, pit: data?.id }));
      } else {
      }

      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    // {
    //   title: "Image",
    //   dataIndex: "uid",
    //   key: "uid",
    //   className: "table_first_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <div style={{ width: "50px" }}>
    //           <img
    //             src={URL?.API_BASE_URL + item?.image}
    //             alt=""
    //             style={{ width: "100%" }}
    //           />
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Pit Name",
      dataIndex: "name",
      key: "name",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.pit?.name}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Pit waste",
    //   dataIndex: "pit_waste",
    //   key: "pit_waste",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.pit_waste} kg
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Soil",
      dataIndex: "soil",
      key: "soil",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.soil} kg
            </span>
          </>
        );
      },
    },
    {
      title: "Waste Weight",
      dataIndex: "waste_weight",
      key: "waste_weight",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.waste_weight} kg
            </span>
          </>
        );
      },
    },
    {
      title: "Water",
      dataIndex: "water",
      key: "water",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.water} L
            </span>
          </>
        );
      },
    },
    {
      title: "Dung",
      dataIndex: "dung",
      key: "dung",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.dung} kg
            </span>
          </>
        );
      },
    },

    // {
    //   title: "Dung",
    //   dataIndex: "dung",
    //   key: "dung",
    // },

    {
      title: "Date",
      dataIndex: "Join Date",
      key: "Join Date",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.date}
              {/* {moment(item?.created_at).format(" DD/MM/YYYY ")} */}
            </span>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                                hendleModalShow(item?.id)
                              }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?.id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(allapiAction.getPitWiseHistory({ page: 1, pit: data?.id }));

    return () => {};
  }, []);

  // const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(allapiAction.allcategorySearch({ page: 1, search: e }));
      return () => {};
    } else {
      dispatch(allapiAction.getPitWiseHistory({ page: 1, pit: data?.id }));

      return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const [startDate, setStartDate] = useState(null);

  const hendletodate = (e) => {
    setStartDate(e);

    // 2023-12-19
    setPage(1);
    dispatch(
      allapiAction.getPitWiseHistoryfilter({
        page: 1,
        search: "",
        pit: data?.id,
        date: moment(e).format("YYYY-MM-DD"),
      })
    );
    // dispatch(allapiAction.getWasteStorageHistory(page));

    return () => {};
  };


  const hendleToresat = (e) => {
    setPage(1);
    // setsearch("");
    // setsearch2("");
    setStartDate(null);
    // dispatch(allapiAction.get_pits_paginate(1));
    dispatch(allapiAction.getPitWiseHistory({ page: e, pit: data?.id }));
  };
  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      // dispatch(allapiAction.allcategorySearch({ page: e, search: search }));
      dispatch(
        allapiAction.getPitWiseHistoryfilter({
          page: e,

          pit: data?.id,
          date: moment(startDate).format("YYYY-MM-DD"),
        })
      );
      return () => {};
    } else {
      dispatch(allapiAction.getPitWiseHistory({ page: e, pit: data?.id }));

      return () => {};
    }
  };

  const loadOptionsgetQuizUpdate = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    console.log(page);

    const response = await fetch(
      URL.API_BASE_URL +
        `/api/admin/get_pits_paginate_search?search=${search}&page=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const userdata = responseJSON?.data ? responseJSON?.data : [];

    const options = userdata?.map((data) => {
      data.value = data?.id;
      data.label = data?.name;

      return data;
    });

    console.log(options);

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page?.page + 1,
      },
    };
  };

  return (
    <div>
      {/* update */}
      <Modal
        title="Update Waste In Pit"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Waste Weight</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="waste_weight"
            name="waste_weight"
            type="number"
            value={cateDetail?.waste_weight}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Soil</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="soil"
            name="soil"
            type="number"
            value={cateDetail?.soil}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Dung</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="dung"
            name="dung"
            type="number"
            value={cateDetail?.dung}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Water</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="water"
            name="water"
            type="number"
            value={cateDetail?.water}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Date</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <DatePicker
            className="form-control"
            selected={startDate3}
            onChange={(date) => setStartDate3(date)}
            placeholderText="Select a date"
            // showTimeSelect
            // showTimeSelectOnly
            // timeIntervals={15}
            // timeCaption="Time"
          />
        </Col>
        {/* <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="pit capacity"
            name="pit_capacity"
            type="number"
            value={cateDetail?.pit_capacity}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col> */}
        {/* <Col span={24}>Image</Col>
        <Col span={24}>
          <ImageUploading
            // multiple
            value={images2}
            onChange={onChange2}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  className="btn btn-primary btn-sm rounded"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Click or Drop here
                </button>
                &nbsp;
                
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image["data_url"]} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded mb-2"
                        onClick={() => onImageUpdate(index)}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-primary btn-sm rounded"
                        onClick={() => onImageRemove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </Col> */}
      </Modal>

      {/* create */}
      <Modal
        title={"Add Waste In " + nameParam}
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        {/* <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          <label>Select Pit</label>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          

          <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetQuizUpdate}
            onChange={handleChangepit}
            additional={{
              page: 1,
            }}
          />
        </Col> */}
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Waste weight</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="waste weight"
            name="waste_weight"
            type="number"
            value={cateDetailAdd?.waste_weight}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Soil</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="soil"
            name="soil"
            type="number"
            value={cateDetailAdd?.soil}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Dung</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="dung"
            name="dung"
            type="number"
            value={cateDetailAdd?.dung}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Water</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-3">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="water"
            name="water"
            type="number"
            value={cateDetailAdd?.water}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>

        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <lable>Date</lable>
        </Col>
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <DatePicker
            className="form-control"
            selected={startDate2}
            onChange={(date) => setStartDate2(date)}
            placeholderText="Select a date"
            // showTimeSelect
            // showTimeSelectOnly
            // timeIntervals={15}
            // timeCaption="Time"
          />
        </Col>

        {/* <Col span={24}>Image</Col>
        <Col span={24}>
          <ImageUploading
            // multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  className="btn btn-primary btn-sm rounded"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Click or Drop here
                </button>
                &nbsp;
            
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image["data_url"]} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded mb-2"
                        onClick={() => onImageUpdate(index)}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-primary btn-sm rounded"
                        onClick={() => onImageRemove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </Col> */}
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={nameParam + " History"}
            subHeading={`Total ${users_list?.total || 0} Pit History`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
          <DatePicker
            className="form-control"
            selected={startDate}
            onChange={(date) => hendletodate(date)}
            placeholderText="Select a date"
            // showTimeSelect
            // showTimeSelectOnly
            // timeIntervals={15}
            // timeCaption="Time"
          />
        </Col>

        <Col md={2} sm={5} xs={12} className="pl-5  ml-5">
          <div className="flex1 flex justify-content-end align-items-center">
            <Tooltip title="Reset">
              <span className="jop_btn" onClick={() => hendleToresat()}>
                Reset
                {/* <SvgIcon.Add
                  width={40}
                  height={40}
                  className="cursor-pointer"
                /> */}
              </span>
            </Tooltip>
          </div>
        </Col>
        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.total ? users_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default PitHistory;
