import { Col, Modal, Row, Tooltip } from "antd";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import moment from "moment";
import HeadingBack from "../component/HeadingBack";

function BettalviewWin() {
  const data = useParams();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // getProfileDetails

  // user_details

  const details = useSelector((state) =>
    state?.allapi?.battels_details ? state?.allapi?.battels_details : {}
  );

  console.log(details);

  useEffect(() => {
    dispatch(allapiAction.getbDetails({ _id: data?.id }));

    return () => {};
  }, []);

  //   submitwinnerAdmin

  const [allPlayer, setallPlayer] = useState([]);
  const [selectPlayer, setselectPlayer] = useState("");
  const [resultchoosein, setresultchoosein] = useState("");
  const [selectPlayer2, setselectPlayer2] = useState("");

  useEffect(() => {
    const plone = details?.owner ? details?.owner : {};
    const pltwo = details?.playerone ? details?.playerone : {};
    const alpl = [plone, pltwo];
    setallPlayer(alpl);
    console.log(alpl);
  }, [details]);

  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBox2, setstatusDataBox2] = useState(false);
  const [statusDataBox3, setstatusDataBox3] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
  };
  const hendleToViewM2 = (e) => {
    setstatusDataBox2(true);
  };
  const hendleToViewM3 = (e) => {
    setstatusDataBox3(true);
  };
  const hendleToSubmetUpdate = () => {
    if (!selectPlayer) {
      toast.error("Please select player");
    } else {
      dispatch(
        allapiAction.submitwinnerAdmin(
          {
            gameId: data?.id,
            winnerID: selectPlayer,
          },
          navigate
        )
      );

      setstatusDataBox(false);
      return () => {};
    }
  };

  const hendleToSubmetUpdate2 = () => {
    if (!resultchoosein) {
      toast.error("Please select result");
    } else {
      if (resultchoosein == "win" || resultchoosein == "loss") {
        if (!selectPlayer2) {
          toast.error("Please select player");
        } else {
          dispatch(
            allapiAction.submitwinnerAdmincencel(
              {
                gameId: data?.id,
                winnerID: selectPlayer2,
                resulttype: resultchoosein,
              },
              navigate
            )
          );
          setselectPlayer2("");
          setresultchoosein("");
          setstatusDataBox2(false);
          return () => {};
        }
      } else {
        dispatch(
          allapiAction.submitwinnerAdmincencel(
            {
              gameId: data?.id,
              winnerID: selectPlayer2,
              resulttype: resultchoosein,
            },
            navigate
          )
        );

        setselectPlayer2("");
        setresultchoosein("");
        setstatusDataBox2(false);
        return () => {};
      }
    }
  };
  const hendleToSubmetUpdate3 = () => {
    if (!resultchoosein) {
      toast.error("Please select result");
    } else {
      if (resultchoosein == "win" || resultchoosein == "loss") {
        if (!selectPlayer2) {
          toast.error("Please select player");
        } else {
          dispatch(
            allapiAction.submitwinnerAdmincencelCron(
              {
                gameId: data?.id,
                winnerID: selectPlayer2,
                resulttype: resultchoosein,
              },
              navigate
            )
          );
          setselectPlayer2("");
          setresultchoosein("");
          setstatusDataBox3(false);
          return () => {};
        }
      } else {
        dispatch(
          allapiAction.submitwinnerAdmincencelCron(
            {
              gameId: data?.id,
              winnerID: selectPlayer2,
              resulttype: resultchoosein,
            },
            navigate
          )
        );

        setselectPlayer2("");
        setresultchoosein("");
        setstatusDataBox3(false);
        return () => {};
      }
    }
  };

  function maskNumber(number) {
    if (number) {
      const numberString = number.toString(); // Convert the number to a string
      const maskedPortion = numberString.slice(0, -2).replace(/\d/g, "*"); // Replace all but the last 2 digits with asterisks
      const lastTwoDigits = numberString.slice(-2); // Get the last 2 digits
      return maskedPortion + lastTwoDigits;
    }
    // Combine the masked portion and last 2 digits
  }
  return (
    <div className="code-box">
      <Modal
        title="Select Winner"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Select Winner</Col>
            <Col span={24}>
              {/* <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              /> */}

              <select
                className="form-control bg-transparent"
                onChange={(e) => {
                  setselectPlayer(e.target.value);
                }}
              >
                <option value={""}>Select winner</option>
                {allPlayer &&
                  allPlayer?.map((data, i) => {
                    return (
                      <option value={data?._id}>
                        {data?.number}- {data?.uid}
                      </option>
                    );
                  })}
              </select>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* cencel */}
      <Modal
        title="Update "
        centered
        open={statusDataBox2}
        onOk={() => hendleToSubmetUpdate2(false)}
        onCancel={() => setstatusDataBox2(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Select </Col>
            <Col span={24}>
              <select
                className="form-control bg-transparent"
                onChange={(e) => {
                  setresultchoosein(e.target.value);
                }}
              >
                <option value={""}>Select </option>

                <option value={"win"}>Win</option>
                <option value={"loss"}>Loss</option>
                <option value={"cancel"}>cancel</option>
              </select>
            </Col>
            {resultchoosein !== "cancel" && <Col span={24}>Select User</Col>}
            {resultchoosein !== "cancel" && (
              <Col span={24}>
                <select
                  className="form-control bg-transparent"
                  onChange={(e) => {
                    setselectPlayer2(e.target.value);
                  }}
                >
                  <option value={""}>Select User</option>
                  {allPlayer &&
                    allPlayer?.map((data, i) => {
                      return (
                        <option value={data?._id}>
                          {data?.number}- {data?.uid}
                        </option>
                      );
                    })}
                </select>
              </Col>
            )}
          </Row>
        </div>
      </Modal>

      {/* cron */}
      <Modal
        title="Update "
        centered
        open={statusDataBox3}
        onOk={() => hendleToSubmetUpdate3(false)}
        onCancel={() => setstatusDataBox3(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Select </Col>
            <Col span={24}>
              <select
                className="form-control bg-transparent"
                onChange={(e) => {
                  setresultchoosein(e.target.value);
                }}
              >
                <option value={""}>Select </option>

                <option value={"win"}>Win</option>
                <option value={"loss"}>Loss</option>
               
              </select>
            </Col>
            {resultchoosein !== "cancel" && <Col span={24}>Select User</Col>}
            {resultchoosein !== "cancel" && (
              <Col span={24}>
                <select
                  className="form-control bg-transparent"
                  onChange={(e) => {
                    setselectPlayer2(e.target.value);
                  }}
                >
                  <option value={""}>Select User</option>
                  {allPlayer &&
                    allPlayer?.map((data, i) => {
                      return (
                        <option value={data?._id}>
                          {data?.number}- {data?.uid}
                        </option>
                      );
                    })}
                </select>
              </Col>
            )}
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Battles"}
            // subHeading={`Total ${users_list?.totalDocs || 0} Users`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {details?.completeby == "cron" ? (
          <div className="flex1 flex justify-content-end align-items-center">
            <Tooltip title=" Decler Result">
              <span className="jop_btn" onClick={() => hendleToViewM3()}>
                Decler Result
              </span>
            </Tooltip>
          </div>
        ) : details?.ownerresult == "cancel" ||
          details?.playeroneresult == "cancel" ? (
          <div className="flex1 flex justify-content-end align-items-center">
            <Tooltip title=" Decler Result">
              <span className="jop_btn" onClick={() => hendleToViewM2()}>
                Decler Result
              </span>
            </Tooltip>
          </div>
        ) : (
          <div className="flex1 flex justify-content-end align-items-center">
            <Tooltip title="Decler Result">
              <span className="jop_btn" onClick={() => hendleToViewM()}>
                Decler Result
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="d-flex flex-column flex-column-fluid">
        {/* <!--begin::Toolbar-->  */}
        <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
          {/* <!--begin::Toolbar container-->  */}
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-flex flex-stack"
          >
            {/* <!--begin::Page title-->  */}
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
              {/* <!--begin::Title-->  */}
              <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                Battles Details
              </h1>
            </div>
            {/* <!--end::Page title-->  */}
            {/* <!--begin::Actions-->  */}
          </div>
          {/* <!--end::Toolbar container-->  */}
        </div>
        {/* <!--end::Toolbar-->  */}
        {/* <!--begin::Content-->  */}
        <div id="kt_app_content" className="app-content flex-column-fluid">
          {/* <!--begin::Content container-->  */}
          <div
            id="kt_app_content_container"
            className="app-container container-xxl"
          >
            {/* <!--begin::Card-->  */}
            <div className="card card-flush">
              {/* <!--begin::Card header-->  */}
              <div className="card-header mt-6">
                {/* <!--begin::Card title-->  */}
                <div className="card-title"></div>
                {/* <!--end::Card title-->  */}
                {/* <!--begin::Card toolbar-->  */}
                <div className="card-toolbar"></div>
              </div>
              {/* <!--end::Card header-->  */}
              {/* <!--begin::Card body-->  */}
              <div className="card-body pt-0">
                <div>
                  <h3>Type :- {details?.completeby}</h3>
                </div>
                <div className="row justify-content-center  align-items-center">
                  <div className="col-6">
                    <div className="row justify-content-center">
                      {/* <div className='col-2'>
                        <img src='' alt='' />
                      </div> */}

                      <div className="col-10">
                        {/* blank.png */}
                        <div style={{ width: "30px" }}>
                          <img
                            style={{ width: "100%", borderRadius: "50%" }}
                            src="/media/blank.png"
                            alt=""
                          />
                        </div>

                        {details?.ownerresult == "win" ? (
                          <div className="row">
                            <div className="col-1">
                              <div style={{ width: "30px" }}>
                                <img
                                  style={{ width: "100%" }}
                                  src="/media/ic/winner.png"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div className="col-11">
                              <p>Winner</p>
                            </div>
                          </div>
                        ) : details?.ownerresult == "loss" ? (
                          <div className="row">
                            <div className="col-1">
                              <div style={{ width: "30px" }}>
                                <img
                                  style={{ width: "100%" }}
                                  src="/media/ic/losser.jpg"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div className="col-11">
                              <p>Loser</p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* <div className='row'>
                          <div className='col-1'>
                          <div style={{width:"30px"}}>
                            <img style={{width:"100%"}} src='/media/ic/winner.png' alt='' />
                          </div>
                          </div>
                         
                          <div className='col-11'>
                            <p>Winner</p>
                          </div>
                        </div> */}

                        <p>{details?.owner?.uid}</p>
                        <p>{details?.owner?.number}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row justify-content-center">
                      {/* <div className='col-2'>
                        <img src='' alt='' />
                      </div> */}
                      <div className="col-10 ">
                        <div style={{ width: "30px" }}>
                          <img
                            style={{ width: "100%", borderRadius: "50%" }}
                            src="/media/blank.png"
                            alt=""
                          />
                        </div>
                        {details?.playeroneresult == "win" ? (
                          <div className="row">
                            <div className="col-1">
                              <div style={{ width: "30px" }}>
                                <img
                                  style={{ width: "100%" }}
                                  src="/media/ic/winner.png"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div className="col-11">
                              <p>Winner</p>
                            </div>
                          </div>
                        ) : details?.playeroneresult == "loss" ? (
                          <div className="row">
                            <div className="col-1">
                              <div style={{ width: "30px" }}>
                                <img
                                  style={{ width: "100%" }}
                                  src="/media/ic/losser.jpg"
                                  alt=""
                                />
                              </div>
                            </div>

                            <div className="col-11">
                              <p>Loser</p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <p>{details?.playerone?.uid}</p>
                        <p>{details?.playerone?.number}</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className='col-4'>
                    <div className='row'>Win</div>
                  </div> */}

                  {/* <div className='col-4'>
                    <div className='row'>
                      <div className='col-6'>Loss</div>
                      <div className='col-6'>verify</div>
                    </div>
                  </div> */}
                  <div className="col-12">
                    <table className="userDetailTable">
                      <tr className="userDetailTablet">
                        <th className="userDetailTablet2">Battle Details</th>
                        <th className="userDetailTablet2"></th>
                        <th className="userDetailTablet2"></th>
                        <th className="userDetailTablet2"></th>
                        {/* <th className='userDetailTablet2'></th>
                        <th className='userDetailTablet2'></th>
                        <th className='userDetailTablet2'></th> */}
                      </tr>
                      <tr>
                        <td>Battle id</td>
                        <td>{details?._id}</td>
                        <td>Labby Id</td>
                        <td>{details?.roomcode}</td>
                      </tr>
                      <tr>
                        <td>Creator id</td>
                        <td>
                          <Link to={"/user-details/" + details?.owner?.uid}>
                            {" "}
                            {details?.owner?._id}{" "}
                          </Link>{" "}
                        </td>
                        <td>Joiner Id</td>
                        <td>
                          <Link to={"/user-details/" + details?.playerone?.uid}>
                            {" "}
                            {details?.playerone?._id}
                          </Link>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>Creator Uid</td>
                        <td>{details?.owner?.uid}</td>
                        <td>Joiner Uid</td>
                        <td>{details?.playerone?.uid}</td>
                      </tr>
                      <tr>
                        <td>Creator Result</td>
                        <td>{details?.ownerresult}</td>
                        <td>Joiner Result</td>
                        <td>{details?.playeroneresult}</td>
                      </tr>
                      <tr>
                        <td>Creator start time </td>
                        <td>
                          {moment(details?.ownerDateStart).format(
                            " DD/MM/YYYY hh:mm A"
                          )}
                        </td>
                        <td>Joiner start time</td>
                        <td>
                          {moment(details?.palyerOneDateJoin).format(
                            " DD/MM/YYYY hh:mm A"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Creator Result Submit</td>
                        <td>
                          {moment(details?.ownerDateSubmitResult).format(
                            " DD/MM/YYYY hh:mm A"
                          )}
                        </td>
                        <td>Joiner Result Submit</td>
                        <td>
                          {moment(details?.palyerOneDateSubmitResult).format(
                            " DD/MM/YYYY hh:mm A"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Entry Fee</td>
                        <td>{details?.amount}</td>
                        <td>Prize</td>
                        <td>{details?.prize}</td>
                      </tr>
                      <tr>
                        <td>Creator cancel reason</td>
                        <td>{details?.ownersreason}</td>
                        <td>Join cancel reason</td>
                        <td>{details?.playeronesreason}</td>
                      </tr>
                      <tr>
                        <td>Game Status</td>
                        <td>{details?.status}</td>
                        <td>Is Running</td>
                        <td>
                          {details?.status == "running"
                            ? "running"
                            : "not running"}
                        </td>
                      </tr>
                      <tr>
                        <td>Player One Image</td>
                        <td>
                          {" "}
                          <div style={{ width: "400px" }}>
                            <img
                              style={{ width: "100%" }}
                              src={URL.API_BASE_URL + details.ownerssimg}
                              alt=""
                            />
                          </div>
                        </td>
                        <td>Player Two Image</td>
                        <td>
                          {" "}
                          <div style={{ width: "400px" }}>
                            <img
                              style={{ width: "100%" }}
                              src={URL.API_BASE_URL + details.playeronessimg}
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                  {/* <div className='col-4'>
                    <table className='userDetailTable'>
                      <tr className='userDetailTablet'>
                        <th className='userDetailTablet2'>Kyc Doucument Details</th>
                        <th className='userDetailTablet2'></th>
                      </tr>
                      <tr>
                        <td>Doucument Name</td>
                        <td>v3</td>
                      </tr>
                    </table>
                  </div>
                  <div className='col-4'>
                    <table className='userDetailTable'>
                      <tr className='userDetailTablet'>
                        <th className='userDetailTablet2'>Kyc Doucument Details</th>
                        <th className='userDetailTablet2'></th>
                      </tr>
                      <tr>
                        <td>Doucument Name</td>
                        <td>v3</td>
                      </tr>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!--end::Content container-->  */}
        </div>
        {/* <!--end::Content-->  */}
      </div>
    </div>
  );
}

export default BettalviewWin;
