import React, { useEffect, useState } from "react";
import { Table, Tooltip, Col, Form, Input } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { toast } from "react-toastify";
import ImageUploading from "react-images-uploading";
import { URL } from "../../Redux/common/url";
import moment from "moment";
function SubCategory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const users_list = useSelector((state) =>
    state?.allapi?.get_sub_category ? state?.allapi?.get_sub_category : {}
  );

  console.log(users_list);

  const listdata = users_list?.data ? users_list?.data : [];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [showDelelt, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const [cateID, setcateID] = useState("");

  const hendleDelete = async (e) => {
    console.log(e);
    // deleteProduct
    const response = await dispatch(
      allapiAction.delete_waste_sub_category({ id: e, page: page })
    );

    if (response?.status) {
      setShowDelete(false);
      dispatch(allapiAction.get_waste_sub_category(page));
    } else {
    }

    return () => {};
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const hendleModalShow = (e) => {
    // setShowDelete(true)
    setcateID(e);
    setIsModalOpen(true);
  };

  const [cateDetail, setcateDetail] = useState({});

  const hendleTochangeCategory = (e) => {
    const { name, value } = e.target;
    setcateDetail({ ...cateDetail, [name]: value });
  };
  const [images2, setImages2] = React.useState([]);
  const showModal = (e) => {
    console.log(e);
    setcateDetail(e);

    setImages2([{ data_url: URL.API_BASE_URL + e.image }]);
    setIsModalOpen(true);
  };

  const onChange2 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages2(imageList);
  };
  const handleOk = async () => {
    const data = new FormData();
    data.append("page", 1);
    data.append("name", cateDetail?.name);
    // data.append("category_id", 2);
    data.append("id", cateDetail?.id);

    if (images2?.length > 0) {
      if (images2[0]?.file) {
        data.append("image", images2[0]?.file);
      }
    }
    const response = await dispatch(
      allapiAction.update_waste_sub_category(data)
    );

    if (response?.status) {
      // dispatch(adminAction.getRoleList('dhsg'))
      setImages2([]);
      setIsModalOpen(false);
      dispatch(allapiAction.get_waste_sub_category(page));
    } else {
    }
    // dispatch(
    //   allapiAction.update_waste_sub_category({
    //     id: cateDetail?.id,
    //     name: cateDetail?.name,
    //     page: page,
    //   })
    // );
    // dispatch(adminAction.getRoleList('dhsg'))

    return () => {};
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [cateDetailAdd, setcateDetailAdd] = useState({});

  const hendleTochangeCategoryAdd = (e) => {
    const { name, value } = e.target;
    setcateDetailAdd({ ...cateDetailAdd, [name]: value });
  };

  const showModalAdd = (e) => {
    console.log(e);
    setcateDetail(e);
    setAddModalOpen(true);
  };

  const [images, setImages] = React.useState([]);

  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  const handleOkAdd = async () => {
    if (!cateDetailAdd?.name) {
      toast.error("please enter name");
    } else {
      const data = new FormData();
      data.append("page", 1);
      data.append("name", cateDetailAdd?.name);
      data.append("category_id", 2);

      if (images?.length > 0) {
        data.append("image", images[0]?.file);
      }
      //   data.append("image", cateDetailAdd?.name);

      const response = await dispatch(allapiAction.waste_sub_category(data));

      if (response?.status) {
        setcateDetailAdd({ name: "" });
        // dispatch(adminAction.getRoleList('dhsg'))
        setImages([]);
        setAddModalOpen(false);
        dispatch(allapiAction.get_waste_sub_category(1));
      } else {
      }

      return () => {};
    }
  };

  const handleCancelAdd = () => {
    setAddModalOpen(false);
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "uid",
      key: "uid",
      className: "table_first_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <div style={{ width: "50px" }}>
              {/* <img
                src={URL?.API_BASE_URL + item?.image}
                alt=""
                style={{ width: "100%" }}
              /> */}
              {item?.image ? (
                <img
                  src={URL?.API_BASE_URL + item?.image}
                  alt=""
                  style={{ width: "100%" }}
                />
              ) : (
                <img
                  src={"/No_image_available.svg.png"}
                  alt=""
                  style={{ width: "100%" }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },

    {
      title: "stock",
      dataIndex: "address",
      key: "address",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.waste_storage?.waste_weight
                ? item?.waste_storage?.waste_weight
                : 0}{" "}
              Kg
            </span>
          </>
        );
      },
    },
    {
      title: "category",
      dataIndex: "address",
      key: "address",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {item?.category?.name}
            </span>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "Join Date",
      key: "Join Date",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.created_at).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showModal(item);
                }}
              >
                <SvgIcon.Edit className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                                hendleModalShow(item?.id)
                              }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?.id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(allapiAction.get_waste_sub_category(1));

    return () => {};
  }, []);

  // const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setsearch(e);
    setPage(1);
    if (e) {
      dispatch(
        allapiAction.get_waste_sub_category_search({ page: 1, search: e })
      );
      return () => {};
    } else {
      dispatch(allapiAction.get_waste_sub_category(1));

      return () => {};
    }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);
    if (search) {
      dispatch(
        allapiAction.get_waste_sub_category_search({ page: e, search: search })
      );
      return () => {};
    } else {
      dispatch(allapiAction.get_waste_sub_category(e));

      return () => {};
    }
  };

  return (
    <div>
      {/* update */}
      <Modal
        title="Update Category"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Col lg={24} md={24} sm={24} xs={24}>
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetail?.name}
            onChange={(e) => {
              hendleTochangeCategory(e);
            }}
          />
        </Col>
        <Col span={24}>Image</Col>
        <Col span={24}>
          <ImageUploading
            // multiple
            value={images2}
            onChange={onChange2}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  className="btn btn-primary btn-sm rounded"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Click or Drop here
                </button>
                &nbsp;
                {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image["data_url"]} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded mb-2"
                        onClick={() => onImageUpdate(index)}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-primary btn-sm rounded"
                        onClick={() => onImageRemove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </Col>
      </Modal>

      {/* create */}
      <Modal
        title="Create Category"
        open={addModalOpen}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
      >
        <Col lg={24} md={24} sm={24} xs={24} className="mt-4">
          <input
            autoComplete="new-off"
            className="form-control"
            placeholder="name"
            name="name"
            value={cateDetailAdd?.name}
            onChange={(e) => {
              hendleTochangeCategoryAdd(e);
            }}
          />
        </Col>
        <Col span={24} className="mt-4">
          Image
        </Col>
        <Col span={24} className="mt-4">
          <ImageUploading
            // multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  className="btn btn-primary btn-sm rounded"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Click or Drop here
                </button>
                &nbsp;
                {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image["data_url"]} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded mb-2"
                        onClick={() => onImageUpdate(index)}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-primary btn-sm rounded"
                        onClick={() => onImageRemove(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </Col>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Dry Category"}
            subHeading={`Total ${users_list?.total || 0} Dry Category`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => showModalAdd()}>
              Add In Dry
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          dataSource={listdata}
          columns={columns}
          className={"table antd_table"}
          pagination={{
            current: page,
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.total ? users_list?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default SubCategory;
