import {
  LOADING,
  FAQS_LIST,
  USERS_LIST,
  USERS_CONTACT_LIST,
  CATEGORY_LIST,
  QUIZ_LIST,
  QUESTION_LIST,
  DESHBOARD,
  BATTEL_LIST_ADMIN,
  WINNING_REQ,
  WITHDRAWAL_LIST,
  KYS_LIST,
  GET_TRANSACTION_LISTS,
  USER_DETAILS,
  BATTEL_DETAILS,
  GET_SETTING,
  DESHBOARD_STATE,
  ACCOUNT_LIST,
  FUND_REQ_LIST,
  GET_MESSAGE_LISTS,
  NOTIFICATION_ALL,
  SUB_CATEGORY,
  PANCHAYAT_LIST,
  PIT_LIST,
  PIT_WASTE_LIST,
  COMPOSIT_LIST,
  DONATION_LIST,
  SPENDATURE_LIST,
  SALE_LIST,
  WASTE_STORE_LIST,
  PIT_HISTORY_LIST,
  SLIDER_LIST,
  CONTACT_DATA,
} from "../common/constant";

const initialState = {
  loading: false,

  categories_delete: {},
  users_list: {},
  users_contact_list: {},
  category_list: [],
  quiz_list: {},
  question_list: {},
  deshboard: {},
  battels_list_admin: {},
  winning_req: {},
  withdrwarl_list: {},
  kyc_list: {},
  get_transaction_list: {},
  user_details: {},
  battels_details: {},
  get_setting: {},
  account_list: {},
  fund_req_list: {},
  get_message_list: {},
  get_notifaction_all: {},
  get_sub_category: {},
  get_paynchayt_list: {},
  get_pit_list: {},
  get_pit_waste_history: {},
  get_composit_history: {},
  get_donation_list: {},
  get_spendature_list: {},
  get_sale_list: {},
  get_wast_store_list: {},
  get_pit_history_list: {},
  get_slider_list: {},
  get_constct_data: {},

  faqs_list: [],
  deshboard_state: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };

    case FAQS_LIST:
      return { ...state, faqs_list: action.payload };
    case USERS_LIST:
      return { ...state, users_list: action.payload };
    case USERS_CONTACT_LIST:
      return { ...state, users_contact_list: action.payload };
    case CATEGORY_LIST:
      return { ...state, category_list: action.payload };
    case QUIZ_LIST:
      return { ...state, quiz_list: action.payload };
    case QUESTION_LIST:
      return { ...state, question_list: action.payload };
    case DESHBOARD:
      return { ...state, deshboard: action.payload };
    case BATTEL_LIST_ADMIN:
      return { ...state, battels_list_admin: action.payload };
    case WINNING_REQ:
      return { ...state, winning_req: action.payload };
    case WITHDRAWAL_LIST:
      return { ...state, withdrwarl_list: action.payload };
    case KYS_LIST:
      return { ...state, kyc_list: action.payload };
    case GET_TRANSACTION_LISTS:
      return { ...state, get_transaction_list: action.payload };
    case USER_DETAILS:
      return { ...state, user_details: action.payload };
    case BATTEL_DETAILS:
      return { ...state, battels_details: action.payload };
    case GET_SETTING:
      return { ...state, get_setting: action.payload };
    case DESHBOARD_STATE:
      return { ...state, deshboard_state: action.payload };
    case ACCOUNT_LIST:
      return { ...state, account_list: action.payload };
    case FUND_REQ_LIST:
      return { ...state, fund_req_list: action.payload };
    case GET_MESSAGE_LISTS:
      return { ...state, get_message_list: action.payload };
    case NOTIFICATION_ALL:
      return { ...state, get_notifaction_all: action.payload };
    case SUB_CATEGORY:
      return { ...state, get_sub_category: action.payload };
    case PANCHAYAT_LIST:
      return { ...state, get_paynchayt_list: action.payload };
    case PIT_LIST:
      return { ...state, get_pit_list: action.payload };
    case PIT_WASTE_LIST:
      return { ...state, get_pit_waste_history: action.payload };
    case COMPOSIT_LIST:
      return { ...state, get_composit_history: action.payload };
    case DONATION_LIST:
      return { ...state, get_donation_list: action.payload };
    case SPENDATURE_LIST:
      return { ...state, get_spendature_list: action.payload };
    case SALE_LIST:
      return { ...state, get_sale_list: action.payload };
    case WASTE_STORE_LIST:
      return { ...state, get_wast_store_list: action.payload };
    case PIT_HISTORY_LIST:
      return { ...state, get_pit_history_list: action.payload };
    case SLIDER_LIST:
      return { ...state, get_slider_list: action.payload };
    case CONTACT_DATA:
      return { ...state, get_constct_data: action.payload };
    // case OTP_BOX_FORGOT:
    //   return { ...state, serach_pro_pub: action.payload };

    default:
      return state;
  }
};
