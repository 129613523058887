import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";

import { Button, Modal, Space } from "antd";
import { URL } from "../../Redux/common/url";
import { AsyncPaginate } from "react-select-async-paginate";

function Bettel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  // const searchParams = new URLSearchParams(window.location.search);
  // const pageNumber = searchParams.get("page");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  console.log(searchParams);
  console.log(page);
  console.log(searchData);

  const battels_list_admin = useSelector((state) =>
    state?.allapi?.battels_list_admin ? state?.allapi?.battels_list_admin : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = battels_list_admin?.data ? battels_list_admin?.data : [];

  useEffect(() => {
    if (search) {
      dispatch(
        allapiAction.getsearchBettalList({ pageNumber: page, key: search })
      );
    } else {
      dispatch(allapiAction.getBettalList(page));
    }

    return () => {};
  }, [location]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.addFoundByAdmin({
        id: userData?.id,
        amount: statusData?.amount,

        pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.deleteByAdminBettal({
        _id: userId,

        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [seleceteManger, setseleceteManger] = useState("");
  console.log(seleceteManger);

  const hendleTofilter = () => {
    dispatch(
      allapiAction.quiz_list({
        page: 1,
        category_id: seleceteManger?.id,
        type: "All",
      })
    );

    return () => {};
  };
  const handleFilterReset = () => {
    // dispatch(
    //   allapiAction.quiz_list({
    //     page: 1,
    //     category_id: 0,
    //     type: "all",
    //   })
    // );
    setseleceteManger(null);
    // setseleceteManger(e);
    setPage(1);
    navigate("/battle?page=" + 1);
    return () => {};
  };
  const loadOptionsgetQuizUpdate = async (search, loadedOptions, page) => {
    const token = await localStorage.getItem("admin_token");

    // roledataUpdate

    console.log(page);

    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/getUserListseacrchforfilter?key=${search}&pageNumber=${page?.page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseJSON = await response.json();

    console.log(responseJSON);

    const options = responseJSON?.results?.map((data) => {
      data.value = data?._id;
      data.label = data?.uid + "-" + data?.number;

      return data;
    });

    console.log(options);

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page?.page + 1,
      },
    };
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="   "
              // onClick={() => navigate(`/battle-view/${item?._id}`)}
            >
              {item?.user?.name}
            </span>
          </>
        );
      },
    },
    {
      title: "User Role",
      dataIndex: "role",
      key: "role",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="   "
              // onClick={() => navigate(`/battle-view/${item?._id}`)}
            >
              {item?.user?.role}
            </span>
          </>
        );
      },
    },
    {
      title: "User Number",
      dataIndex: "number",
      key: "number",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="  "
              // onClick={() => navigate(`/battle-view/${item?._id}`)}
            >
              {item?.user?.mobile}
            </span>
          </>
        );
      },
    },
    

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   key: "action",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact">
    //           <span
    //             className="cursor-pointer"
    //             onClick={() => navigate(`/users/contact/${item?.id}`)}
    //           >
    //             <SvgIcon.contacts className="scale-icon edit-icon" />
    //           </span>
    //         </Tooltip> */}
    //         <Tooltip title="delete">
    //           <button
    //             className="filter-button ml-3"
    //             onClick={() => showModal(item?._id)}
    //           >
    //             Delete
    //           </button>
    //         </Tooltip>
    //         {/* <Tooltip title="Add Point">
    //           <button
    //             className="filter-button ml-3  mt-5"
    //             onClick={() => hendleToViewM(item)}
    //           >
    //             Add Point
    //           </button>
    //         </Tooltip> */}
    //       </>
    //     );
    //   },
    // },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    console.log(e);
    setseleceteManger(e);
    setPage(1);
    navigate("/battle?page=" + 1 + "&search=" + e?._id);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);
    console.log(seleceteManger);

    if (seleceteManger) {
      navigate("/battle?page=" + e + "&search=" + seleceteManger?._id);
      // navigate("/point-request?page=" + e);
    } else {
      navigate("/battle?page=" + e);
    }

    // navigate("/battle?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="delete"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Amount"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Suggestion"}
            subHeading={`Total ${battels_list_admin?.total || 0} Suggestion`}
          />
        </div>
        {/* <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetQuizUpdate}
            onChange={handleChange}
            additional={{
              page: 1,
            }}
          />
        </Col>

        <div className="pl-5 ml-5">
          <button
            className="clear-button clear-button1"
            onClick={() => handleFilterReset()}
          >
            Reset
          </button>
        </div> */}

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: battels_list_admin?.total ? battels_list_admin?.total : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Bettel;
