import React, { useEffect, useState } from "react";
import {
  DashboardOutlined,
  UsergroupAddOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  SettingOutlined,
  UserOutlined,
  FileOutlined,
  ApartmentOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { Badge, Layout, Menu } from "antd";
import SidebarFooter from "./SidebarFooter";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { useBasePath } from "../../hooks/useBasePath";
import SvgIcon from "../../const/SvgIcon";
import { ShowMenu } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../../Redux/common/action";
const { Sider } = Layout;

const Sidebar = ({ collapsed }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openKey, selectKey } = useBasePath();
  const get_notifaction_all = useSelector((state) =>
    state?.allapi?.get_notifaction_all ? state?.allapi?.get_notifaction_all : {}
  );

  return (
    <Sider
      className="layout_sidebar"
      collapsible
      collapsed={collapsed}
      width={240}
      trigger={!collapsed ? <SidebarFooter /> : null}
    >
      <Logo collapsed={collapsed} />
      <div className="sidebar_menus">
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={selectKey}
          defaultOpenKeys={openKey}
          style={{ background: "#000000" }}
          items={[
            {
              key: "dashboard",
              icon: <DashboardOutlined />,
              label: "Dashboard",
              onClick: () => {
                navigate("/");
              },
            },

            {
              key: "user-management",
              icon: <QuestionCircleOutlined />,
              label: "Users",
              style: {
                display: ShowMenu(["Users", "Driver"]),
              },
              children: [
                {
                  key: "users",
                  label: "Users",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Users"]) },
                  onClick: () => {
                    navigate("/users");
                  },
                },

                {
                  key: "driver",
                  label: "Driver",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Driver"]) },
                  onClick: () => {
                    navigate("/driver");
                  },
                },
              ],
            },
            // {
            //   key: "users",
            //   icon: <QuestionCircleOutlined />,
            //   label: "Users",
            //   style: { display: ShowMenu(["Users"]) },
            //   onClick: () => {
            //     navigate("/users");
            //   },
            // },
            // {
            //   key: "category",
            //   icon: <FileSearchOutlined />,
            //   label: "Category",
            //   style: { display: ShowMenu(["Category"]) },
            //   onClick: () => {
            //     navigate("/category");
            //   },
            // },
            {
              key: "sub-category",
              icon: <FileSearchOutlined />,
              label: "Dry Category",
              style: { display: ShowMenu(["Dry Category"]) },
              onClick: () => {
                navigate("/sub-category");
              },
            },

            {
              key: "panchayat",
              icon: <FileSearchOutlined />,
              label: "Panchayat",
              style: { display: ShowMenu(["Panchayat"]) },
              onClick: () => {
                navigate("/panchayat");
              },
            },
            {
              key: "store-waste",
              icon: <FileSearchOutlined />,
              label: "Waste collection",
              style: { display: ShowMenu(["Waste collection"]) },
              onClick: () => {
                navigate("/store-waste");
              },
            },
            {
              key: "slider",
              icon: <FileSearchOutlined />,
              label: "Slider",
              style: { display: ShowMenu(["Slider"]) },
              onClick: () => {
                navigate("/slider");
              },
            },
            {
              key: "contact",
              icon: <FileSearchOutlined />,
              label: "Contact",
              style: { display: ShowMenu(["Contact"]) },
              onClick: () => {
                navigate("/contact");
              },
            },
            // {
            //   key: "pit",
            //   icon: <FileSearchOutlined />,
            //   label: "Pit",
            //   style: { display: ShowMenu(["Pit"]) },
            //   onClick: () => {
            //     navigate("/pit");
            //   },
            // },
            // {
            //   key: "waste-in-pit",
            //   icon: <FileSearchOutlined />,
            //   label: "Pit History",
            //   style: { display: ShowMenu(["Pit History"]) },
            //   onClick: () => {
            //     navigate("/waste-in-pit");
            //   },
            // },
            // {
            //   key: "composit",
            //   icon: <FileSearchOutlined />,
            //   label: "Composit",
            //   style: { display: ShowMenu(["Composit"]) },
            //   onClick: () => {
            //     navigate("/composit");
            //   },
            // },

            {
              key: "pit-all",
              icon: <QuestionCircleOutlined />,
              label: "Pit",
              style: {
                display: ShowMenu(["Pit", "Pit History", "Composit"]),
              },
              children: [
                {
                  key: "pit",
                  label: "Pit",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Pit"]) },
                  onClick: () => {
                    navigate("/pit");
                  },
                },

                // {
                //   key: "waste-in-pit",
                //   label: "Pit History",
                //   icon: <QuestionCircleOutlined />,
                //   style: { display: ShowMenu(["Pit History"]) },
                //   onClick: () => {
                //     navigate("/waste-in-pit");
                //   },
                // },
                {
                  key: "composit",
                  label: "Compost",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Compost"]) },
                  onClick: () => {
                    navigate("/composit");
                  },
                },
                // {
                //   key: "sale-waste",
                //   label: "Sale Waste",
                //   icon: <QuestionCircleOutlined />,
                //   style: { display: ShowMenu(["Sale Waste"]) },
                //   onClick: () => {
                //     navigate("/sale-waste");
                //   },
                // },
              ],
            },
            {
              key: "donation-all",
              icon: <QuestionCircleOutlined />,
              label: "Transaction",
              style: {
                display: ShowMenu([
                  "Donation",
                  "Expenditure",
                  "Store Waste",
                  "Sale Waste",
                ]),
              },
              children: [
                {
                  key: "donation",
                  label: "Donation",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Donation"]) },
                  onClick: () => {
                    navigate("/donation");
                  },
                },

                {
                  key: "expenditure",
                  label: "Expenditure",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Expenditure"]) },
                  onClick: () => {
                    navigate("/expenditure");
                  },
                },
                // {
                //   key: "store-waste",
                //   label: "Waste Input",
                //   icon: <QuestionCircleOutlined />,
                //   style: { display: ShowMenu(["Waste Input"]) },
                //   onClick: () => {
                //     navigate("/store-waste");
                //   },
                // },
                {
                  key: "sale-waste",
                  label: "Sale Waste",
                  icon: <QuestionCircleOutlined />,
                  style: { display: ShowMenu(["Sale Waste"]) },
                  onClick: () => {
                    navigate("/sale-waste");
                  },
                },
              ],
            },
            {
              key: "suggestion",
              icon: <FileSearchOutlined />,
              label: "Suggestion",
              style: { display: ShowMenu(["Suggestion"]) },
              onClick: () => {
                navigate("/suggestion");
              },
            },
            // {
            //   key: "donation",
            //   icon: <FileSearchOutlined />,
            //   label: "Donation",
            //   style: { display: ShowMenu(["Donation"]) },
            //   onClick: () => {
            //     navigate("/donation");
            //   },
            // },
            // {
            //   key: "expenditure",
            //   icon: <FileSearchOutlined />,
            //   label: "Expenditure ",
            //   style: { display: ShowMenu(["Expenditure"]) },
            //   onClick: () => {
            //     navigate("/expenditure");
            //   },
            // },
            // {
            //   key: "store-waste",
            //   icon: <FileSearchOutlined />,
            //   label: "Store Waste",
            //   style: { display: ShowMenu(["Store Waste"]) },
            //   onClick: () => {
            //     navigate("/store-waste");
            //   },
            // },

            // {
            //   key: "sale-waste",
            //   icon: <FileSearchOutlined />,
            //   label: "Sale Waste",
            //   style: { display: ShowMenu(["Sale Waste"]) },
            //   onClick: () => {
            //     navigate("/sale-waste");
            //   },
            // },

            // {
            //   key: "winner-request",
            //   icon: <ApartmentOutlined />,
            //   // label: "Winner Request",
            //   label: (
            //     <span>
            //       Winner Request
            //       {get_notifaction_all.winnernotifiction == "true" && (
            //         <Badge count={"W"} style={{ marginLeft: 8 }} />
            //       )}
            //     </span>
            //   ),
            //   style: { display: ShowMenu(["Winner Request"]) },
            //   onClick: () => {
            //     navigate("/winner-request");

            //     dispatch(
            //       allapiAction.getnotificationAdminViewall({
            //         winnernotifiction: "1",
            //       })
            //     );
            //   },
            // },
            // {
            //   key: "withdrawal-request",
            //   icon: <QuestionCircleOutlined />,
            //   // label: "Withdrawal Request",
            //   label: (
            //     <span>
            //       Withdrawal Request
            //       {get_notifaction_all.withdrawalnotifiction == "true" && (
            //         <Badge count={"W"} style={{ marginLeft: 8 }} />
            //       )}
            //     </span>
            //   ),
            //   style: { display: ShowMenu(["Withdrawal Request"]) },
            //   onClick: () => {
            //     navigate("/withdrawal-request");
            //     dispatch(
            //       allapiAction.getnotificationAdminViewall({
            //         withdrawalnotifiction: "1",
            //       })
            //     );
            //   },
            // },
            // {
            //   key: "kyc-pending",
            //   icon: <QuestionCircleOutlined />,
            //   // label: "Kyc Pending",
            //   label: (
            //     <span>
            //       Kyc Pending
            //       {get_notifaction_all.kycnotifiction == "true" && (
            //         <Badge count={"K"} style={{ marginLeft: 8 }} />
            //       )}
            //     </span>
            //   ),
            //   style: { display: ShowMenu(["Kyc Pending"]) },
            //   onClick: () => {
            //     navigate("/kyc-pending");
            //     dispatch(
            //       allapiAction.getnotificationAdminViewall({
            //         kycnotifiction: "1",
            //       })
            //     );
            //   },
            // },
            // {
            //   key: "transaction",
            //   icon: <QuestionCircleOutlined />,
            //   label: "Transaction",
            //   style: { display: ShowMenu(["Transaction"]) },
            //   onClick: () => {
            //     navigate("/transaction");
            //   },
            // },
            // {
            //   key: "setting",
            //   icon: <QuestionCircleOutlined />,
            //   label: "Setting",
            //   style: { display: ShowMenu(["Setting"]) },
            //   onClick: () => {
            //     navigate("/setting");
            //   },
            // },
            // {
            //   key: "account-list",
            //   icon: <QuestionCircleOutlined />,
            //   label: "Account List",
            //   style: { display: ShowMenu(["Account List"]) },
            //   onClick: () => {
            //     navigate("/account-list");
            //   },
            // },
            // {
            //   key: "point-request",
            //   icon: <QuestionCircleOutlined />,
            //   // label: "Point Request",
            //   label: (
            //     <span>
            //       Point Request
            //       {get_notifaction_all.pointnotifiction == "true" && (
            //         <Badge count={"P"} style={{ marginLeft: 8 }} />
            //       )}
            //     </span>
            //   ),
            //   style: { display: ShowMenu(["Point Request"]) },
            //   onClick: () => {
            //     navigate("/point-request");
            //     dispatch(
            //       allapiAction.getnotificationAdminViewall({
            //         pointnotifiction: "1",
            //       })
            //     );
            //   },
            // },
            // {
            //     key: 'faq-management',
            //     icon: <QuestionCircleOutlined />,
            //     label: 'FAQs Management',
            //     style: { display: ShowMenu(["Faqs", 'Faq Category']) },
            //     children: [
            //         {
            //             key: 'faq-category',
            //             label: 'FAQ-Category',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["Faq Category"]) },
            //             onClick: () => {
            //                 navigate("/faq-category")
            //             },
            //         },

            //         {
            //             key: 'faqs',
            //             label: 'FAQs',
            //             icon: <QuestionOutlined />,
            //             style: { display: ShowMenu(["Faqs"]) },
            //             onClick: () => {
            //                 navigate("/faqs")
            //             },
            //         },
            //     ]
            // },
            // {
            //     key: 'investor-management',
            //     icon: <QuestionCircleOutlined />,
            //     label: 'Investor Management',
            //     style: { display: ShowMenu(["investor Category", "Financials Pages", 'investor Pages']) },
            //     children: [
            //         {
            //             key: 'investor Category',
            //             label: 'Categories',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["investor Categorys"]) },
            //             onClick: () => {
            //                 navigate("/investor-category")
            //             },

            //         },

            //         {
            //             key: 'financials',
            //             label: 'Financials',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["Financials Pages"]) },
            //             onClick: () => {
            //                 navigate("/investor-financials")
            //             },
            //         },
            //         {
            //             key: 'investor',
            //             label: 'Investors Information',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["investor Pages"]) },
            //             onClick: () => {
            //                 navigate("/investor-information")
            //             },
            //         },
            //         {
            //             key: 'corporate-governance',
            //             label: 'Corporate Governance',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["investor Pages"]) },
            //             onClick: () => {
            //                 navigate("/investor-corporate-governance")
            //             },
            //         },
            //     ]
            // },
            // {
            //     key: 'seo-management',
            //     icon: <SvgIcon.seoManagementIcon />,
            //     label: 'SEO Management',
            //     style: { display: ShowMenu(["seo", 'Seo Pages']) },
            //     children: [
            //         {
            //             key: 'seo',
            //             label: 'SEO',
            //             icon: <QuestionCircleOutlined />,
            //             style: { display: ShowMenu(["Seo Pages"]) },
            //             onClick: () => {
            //                 navigate("/seo-management")
            //             },
            //         },
            //     ]
            // },
            // {
            //     key: 'ticket-management',
            //     icon: <FileOutlined />,
            //     label: 'Ticket Management',
            //     style: { display: ShowMenu(["Ticket Management"]) },
            //     onClick: () => {
            //         navigate("/ticket-management")
            //     }
            // },
            // {
            //     key: 'hr-management',
            //     icon: <SvgIcon.HrIcon />,
            //     label: 'HR Management',
            //     style: { display: ShowMenu(["Job Applications", "Job Posts"]) },
            //     children: [
            //         {
            //             key: 'job-applications',
            //             label: 'Job Applications',
            //             icon: <FileOutlined />,
            //             style: { display: ShowMenu(["Job Applications"]) },
            //             onClick: () => {
            //                 navigate("job-applications")
            //             },
            //         },
            //         {
            //             key: 'job-post',
            //             label: 'Job Posts',
            //             icon: <FileOutlined />,
            //             style: { display: ShowMenu(["Job Posts"]) },
            //             onClick: () => {
            //                 navigate("jobs")
            //             },
            //         },

            //     ]
            // },
            // {
            //     key: 'user-management',
            //     icon: < UsergroupAddOutlined />,
            //     label: 'Users Management',
            //     style: { display: ShowMenu(["Users", "Roles"]) },
            //     children: [
            //         {
            //             key: 'users',
            //             label: 'Users',
            //             icon: <UserOutlined />,
            //             style: { display: ShowMenu(["Users"]) },
            //             onClick: () => {
            //                 navigate("/users")
            //             },
            //         },
            //         {
            //             key: 'roles',
            //             label: 'Roles',
            //             icon: <UserOutlined />,
            //             style: { display: ShowMenu(["Roles"]) },
            //             onClick: () => {
            //                 navigate("/roles")
            //             },
            //         }
            //     ]
            // },
          ]}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
